import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIUploadFile {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async setFile(id, file, path_server, path_dox, tipo_upload, user, removeUploadFile, nomeAzienda) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("file", file);
        formData.append("path_server", path_server);
        formData.append("path_dox", path_dox);
        formData.append("tipo_upload", tipo_upload);
        formData.append("user", user);
        formData.append("removeUploadFile", removeUploadFile);
        formData.append("nomeAzienda", nomeAzienda);


        return axios.post(this.getUrlDomain("EmtUploadFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getFile(id, tipo_upload, path_server, user) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("tipo_upload", tipo_upload);
        formData.append("path_server", path_server);
        formData.append("user", user);


        return axios.post(this.getUrlDomain("EmtGetFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APIUploadFile({
    url: "https://services.ebadge.it/public/api/"
})
