<template>
    <v-container class="w-container-users v-overflow createResFromUser"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
                    background-color: white;
                    color: black;
                    font-weight: bold;
                    padding: 4px;
                    font-size: 10px;
                    min-width:100vw;
                    border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>


        <v-form style="max-width:700px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemCompany"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text=""
                                :value="risorsa.companyid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid" :value='item.companyid'> {{
                                    item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_departmentid" style="cursor:pointer">
                        <ion-item id="itemDepartment"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Dipartimento </ion-label>

                            <ion-select id="departmentid" name="departmentid" class='departmentid' cancel-Text="Chiudi"
                                done-Text="" :value="risorsa.departmentid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in dipartimenti" :key="item.departmentid"
                                    :value='item.departmentid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_piano_orario" style="cursor:pointer">
                        <ion-item id="itemPianoOrario"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Piano Orario </ion-label>

                            <ion-select id="codice_piano_orario" name="codice_piano_orario" class='codice_piano_orario'
                                cancel-Text="Chiudi" done-Text="" :value="risorsa.codice_piano_orario"
                                interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in pianiorario" :key="item.codice" :value='item.codice'>
                                    {{ item.descrizione }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>



            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_gruppo" style="cursor:pointer">
                        <ion-item id="itemGruppo"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Gruppo </ion-label>

                            <ion-select id="gruppo_da_associare" name="gruppo_da_associare" class='gruppo_da_associare'
                                cancel-Text="Chiudi" done-Text="" :value="risorsa.id_group_to_associate"
                                interface="action-sheet">

                                <ion-select-option v-for="item in gruppi" :key="item.idresources_group_name" :value='item.idresources_group_name'>
                                    {{ item.name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>





            <v-row>

                <v-col cols="12" md="12">

                    <ion-item id="itemVediStraordinari"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Vedi Straordinari</ion-label>

                        <ion-select id="is_italian_contract" name="is_italian_contract" class='is_italian_contract'
                            cancel-Text="Chiudi" done-Text="" :value="risorsa.view_overtime" interface="action-sheet">

                            <ion-select-option v-for="item in vedistraordinari" :key="item.id" :value='item.id'> {{
                                item.description
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <ion-item id="itemAbilitaEbadge"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Abilita Ebadge</ion-label>

                        <ion-select id="is_ebadge" name="is_ebadge" class='is_ebadge' cancel-Text="Chiudi" done-Text=""
                            :value="risorsa.is_ebadge" interface="action-sheet">

                            <ion-select-option v-for="item in abilitaebadge" :key="item.id" :value='item.id'> {{
                                item.description
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>



            <v-row v-if="showRow9">

                <v-col cols="12" md="12">

                    <ion-item id="itemEbadgeRegtypeQrcode"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Ebadge Regtype Qrcode</ion-label>

                        <ion-select id="ebadge_regtype_qrcode" name="ebadge_regtype_qrcode" class='ebadge_regtype_qrcode'
                            cancel-Text="Chiudi" done-Text="" :value="risorsa.ebadge_regtype_qrcode"
                            interface="action-sheet">

                            <ion-select-option v-for="item in ebadgeregtypeqr" :key="item.id" :value='item.id'> {{
                                item.description
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>


            <v-row v-if="showRow8">

                <v-col cols="12" md="12">

                    <ion-item id="itemEbadgeRegtypeManual"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Ebadge Regtype Manual</ion-label>

                        <ion-select id="ebadge_regtype_manual" name="ebadge_regtype_manual" class='ebadge_regtype_manual'
                            cancel-Text="Chiudi" done-Text="" :value="risorsa.ebadge_regtype_manual"
                            interface="action-sheet">

                            <ion-select-option v-for="item in ebadgeregtypeman" :key="item.id" :value='item.id'> {{
                                item.description
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row v-if="showRow7">

                <v-col cols="12" md="12">

                    <ion-item id="itemEbadgeRegtypeReception"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Ebadge Regtype Reception</ion-label>

                        <ion-select id="ebadge_regtype_reception" name="ebadge_regtype_reception"
                            class='ebadge_regtype_reception' cancel-Text="Chiudi" done-Text=""
                            :value="risorsa.ebadge_regtype_reception" interface="action-sheet">

                            <ion-select-option v-for="item in ebadgeregtyperec" :key="item.id" :value='item.id'> {{
                                item.description
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row v-if="showRow1">

                <v-col cols="12" md="12">

                    <ion-item id="itemEbadgeTipoRegistrazione"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Tipo Registrazione</ion-label>

                        <ion-select id="idebadge_type_registration" name="idebadge_type_registration"
                            class='idebadge_type_registration' cancel-Text="Chiudi" done-Text=""
                            :value="risorsa.idebadge_type_registration" interface="action-sheet">

                            <ion-select-option v-for="item in ebadgetiporegistrazione"
                                :key="item.idebadge_type_registration" :value='item.idebadge_type_registration'> {{
                                    item.description
                                }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>



            <v-row v-if="showRow2">
                <v-col cols="12" md="12">

                    <div id="d_ebadge_pausa"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Pausa
                                        (MM)</label>

                                    <vue-autonumeric v-model="risorsa.min_pausa" :disabled="true" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0'
                                    }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="showRow3">

                <v-col cols="12" md="12">

                    <ion-item id="itemEbadgeArrotondamento"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Arrotondamento per difetto ore</ion-label>

                        <ion-select id="is_enable_rnd_down_hh_ebadge" name="is_enable_rnd_down_hh_ebadge"
                            class='is_enable_rnd_down_hh_ebadge' cancel-Text="Chiudi" done-Text=""
                            :value="risorsa.is_enable_rnd_down_hh_ebadge" interface="action-sheet">

                            <ion-select-option v-for="item in ebadgearrotondamento" :key="item.id" :value='item.id'> {{
                                item.description
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row v-if="showRow4">

                <v-col cols="12" md="12">

                    <ion-item id="itemEbadgeVerificaIP"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Verifica IP</ion-label>

                        <ion-select id="is_check_ip_ebadge" name="is_check_ip_ebadge" class='is_check_ip_ebadge'
                            cancel-Text="Chiudi" done-Text="" :value="risorsa.is_check_ip_ebadge" interface="action-sheet">

                            <ion-select-option v-for="item in ebadgeverificaip" :key="item.id" :value='item.id'> {{
                                item.description
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row v-if="showRow5">

                <v-col cols="12" md="12">

                    <ion-item id="itemEbadgeInviaMail"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Invia Mail</ion-label>

                        <ion-select id="is_check_send_mail" name="is_check_send_mail" class='is_check_send_mail'
                            cancel-Text="Chiudi" done-Text="" :value="risorsa.is_check_send_mail" interface="action-sheet">

                            <ion-select-option v-for="item in ebadgeinviamail" :key="item.id" :value='item.id'> {{
                                item.description
                            }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row v-if="showRow6">

                <v-col cols="12" md="12">

                    <v-text-field v-model="risorsa.ebadge_send_mail_address_to" label="Indirizzo Mail Destinazione">
                    </v-text-field>

                </v-col>

            </v-row>




        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetRisorse" class="vrisorsesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetRisorse" fixed style="position:fixed;">
                            Risorse
                            <v-btn class='closeSheet' icon @click="sheetRisorse = !sheetRisorse">

                                <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="24" max-height="24" style="margin-right:13px"
                                    transition="scale-transition" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredRisorse" :key="item.resourceid"
                                    @click="manageClickRisorse(item)">
                                    <v-icon aria-hidden="false">mdi-account</v-icon>
                                    <v-list-item-title class='itemRisorsa'>{{ item.resource }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <div class="contpopupfidoant">

            <template>
                <modal name="popupFidoAnt" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Fido Anticipi</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="fidoAnt" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-fido-ant" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-fido-ant" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupfidocassa">

            <template>
                <modal name="popupFidoCassa" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Fido Cassa</label>

                            <div id="d_fido_cassa" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="fidoCassa" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldFidoCassa" id="fldFidoCassa"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-fido-cassa" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-fido-cassa" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contdipartimento">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetDipartimento" class="vdipartimentosheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetDipartimento" fixed style="position:fixed;">
                                Dipartimento
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetDipartimento = !sheetDipartimento">

                                    <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchDipartimento"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredDipartimenti"
                                        :key="item.departmentid" @click="manageClickDipartimenti(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemDipartimento'>{{ item.fld_name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contpopuppianoorario">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetPianoOrario" class="vpianoorariosheet">
                        <v-sheet class="text-center" height="100vh">

                            <div class="headerSheetPianoOrario" fixed style="position:fixed;">
                                Piano Orario
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetPianoOrario = !sheetPianoOrario">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchPianoOrario"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <div style="display:flex;min-width:100vw;margin-top:14px;">

                                <div class="first_column_piano_orario">&nbsp;</div>
                                <div class="second_column_piano_orario">Codice</div>
                                <div class="third_column_piano_orario">Descrizione</div>
                                <div class="fourth_column_piano_orario">Totale (HH)</div>

                            </div>


                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredPianoOrario" :key="item.codice"
                                        @click="manageClickPianoOrario(item)">

                                        <v-img alt="PianoOrario Logo" class="  " contain src="@/assets/icons8-orario-80.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemPianoOrarioCodice'>{{ item.codice }}
                                        </v-list-item-title>
                                        <v-list-item-title class='itemPianoOrarioDescrizione'>{{ item.descrizione }}
                                        </v-list-item-title>

                                        <v-list-item-title class='itemPianoOrarioDescrizione'>{{ item.total }}
                                        </v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>

                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopupebadgepausa">

            <template>
                <modal name="popupEbadgePausa" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Pausa (MM)</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreEbadgePausa" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldEbadgePausa" id="fldEbadgePausa"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-ebadgepausa" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-ebadgepausa" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apicespiti from "../utils/cespiti/apicespiti";
import apirisorse from "../utils/risorse/apirisorse";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {


        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;

        //window.$cookies.get("aziende_user")

        this.setupButtons();

        this.titolo = "Crea Risorse";

        setTimeout(() => {


            this.initDDL();


        }, 100);




        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.saveData();

                    break;

                case "btn_back":
                    router.push({
                        path: "/users"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {




            $('body').on('ionChange', '.gruppo_da_associare', function (event) {
                console.log(event);

                pointerVue.risorsa.id_group_to_associate = this.value;

                pointerVue.setupButtons();

            });







            $('body').on('ionChange', '.idebadge_type_registration', function (event) {
                console.log(event);

                pointerVue.risorsa.idebadge_type_registration = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.ebadge_regtype_reception', function (event) {
                console.log(event);

                pointerVue.risorsa.ebadge_regtype_reception = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.ebadge_regtype_manual', function (event) {
                console.log(event);

                pointerVue.risorsa.ebadge_regtype_manual = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.ebadge_regtype_qrcode', function (event) {
                console.log(event);

                pointerVue.risorsa.ebadge_regtype_qrcode = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.is_italian_contract', function (event) {
                console.log(event);

                pointerVue.risorsa.is_italian_contract = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.idebadge_type_registration', function (event) {
                console.log(event);


                console.log("VAL: ", this.value);

                if ((this.value == 2) || (this.value == 3)) {
                    pointerVue.showRow2 = true;
                }

                if ((this.value != 2) && (this.value != 3)) {
                    pointerVue.showRow2 = false;
                }

            });

            $('body').on('click', '#d_ebadge_pausa', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupEbadgePausa');

            });


            $('body').on('click', '.btn-confirm-ebadgepausa', function (event) {
                console.log(event);

                pointerVue.risorsa.min_pausa = pointerVue.valoreEbadgePausa;

                pointerVue.$root.$children[0].setFieldActive("#d_ebadge_pausa");

                pointerVue.$modal.hide('popupEbadgePausa');

            });

            $('body').on('click', '.btn-cancel-ebadgepausa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupEbadgePausa');

            });



            $('body').on('ionChange', '.is_ebadge', function (event) {
                console.log(event);

                pointerVue.risorsa.is_ebadge = this.value;

                if (this.value == "S") {

                    pointerVue.showRow1 = true;
                    pointerVue.showRow3 = true;
                    pointerVue.showRow4 = true;
                    pointerVue.showRow5 = true;
                    pointerVue.showRow6 = true;
                    pointerVue.showRow7 = true;
                    pointerVue.showRow8 = true;
                    pointerVue.showRow9 = true;

                }

                if (this.value == "N") {

                    pointerVue.showRow1 = false;
                    pointerVue.showRow2 = false;
                    pointerVue.showRow3 = false;
                    pointerVue.showRow4 = false;
                    pointerVue.showRow5 = false;
                    pointerVue.showRow6 = false;
                    pointerVue.showRow7 = false;
                    pointerVue.showRow8 = false;
                    pointerVue.showRow9 = false;


                }

                pointerVue.setupButtons();

            });

            $('body').on('click', '#cont_piano_orario', function (event) {
                console.log(event);



                pointerVue.loadPianoOrario($("#companyid").val());



            });




            $('body').on('click', '#cont_departmentid', function (event) {
                console.log(event);

                pointerVue.sheetDipartimento = true;

            });


            // $('body').on('click', '#cont_companyid', function (event) {
            //     console.log(event);

            //     pointerVue.sheetAziende = true;

            // });


            $('body').on('click', '.btn-confirm-fido-ant', function (event) {
                console.log(event);

                pointerVue.banca.fido_anticipi = pointerVue.fidoAnt;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_ant");

                pointerVue.$modal.hide('popupFidoAnt');

            });


            $('body').on('click', '.btn-cancel-fido-ant', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoAnt');

            });

            $('body').on('click', '#d_fido_ant', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoAnt');

            });


            $('body').on('click', '#cont_idresource', function (event) {
                console.log(event);

                pointerVue.sheetRisorse = true;

            });



            $('body').on('click', '#cont_idmailgroup', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });




            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });






            $('body').on('click', '.btn-confirm-fido-cassa', function (event) {
                console.log(event);

                pointerVue.banca.fido_cassa = pointerVue.fidoCassa;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_cassa");

                pointerVue.$modal.hide('popupFidoCassa');

            });


            $('body').on('click', '.btn-cancel-fido-cassa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoCassa');

            });

            $('body').on('click', '#d_fido_cassa', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoCassa');

            });


            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);


            $(".idassettype").on("ionChange", function (opt) {
                console.log(opt);

                if ((this.value == 4) || (this.value == 1) || (this.value == 2) || (this.value == 3)) {

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = true;
                    pointerVue.visOP = true;
                    pointerVue.visSw = true;

                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;


                    pointerVue.visPollici = false;


                }


                if ((this.value == 5) || (this.value == 6)) {

                    pointerVue.visDescrizione = false;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visNSim = true;
                    pointerVue.visTelNum = true;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visPollici = false;


                }

                if (this.value == 7) {

                    pointerVue.visDescrizione = false;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = true;
                    pointerVue.visVeicolo = true;

                    pointerVue.visPollici = false;


                }

                if (this.value == 9) {

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visPollici = false;


                }


                if (this.value == 10) {

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = true;

                    pointerVue.visPollici = false;


                }


                if (this.value == 11) {

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = true;



                }



            });




        });

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredPianoOrario() {

            return _.orderBy(this.pianiorario.filter(item => {
                if (!this.searchPianoOrario) return this.pianiorario;
                return (item.descrizione.toLowerCase().includes(this.searchPianoOrario.toLowerCase()));
            }), 'headline')

        },


        filteredDipartimenti() {

            return _.orderBy(this.dipartimenti.filter(item => {
                if (!this.searchDipartimento) return this.dipartimenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipartimento.toLowerCase()));
            }), 'headline')
        },


        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredRisorse() {

            return _.orderBy(this.risorse.filter(item => {
                if (!this.search) return this.risorse;
                return (item.resource.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


    },

    data: () => ({


        valoreEbadgePausa: null,

        ebadgeregtypeqr: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeregtypeman: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeregtyperec: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgearrotondamento: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeverificaip: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeinviamail: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        vedistraordinari: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],


        showRow1: false,
        showRow2: false,
        showRow3: false,
        showRow4: false,
        showRow5: false,
        showRow6: false,
        showRow7: false,
        showRow8: false,
        showRow9: false,


        abilitaebadge: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],


        pianiorario: [],

        sheetPianoOrario: false,
        searchPianoOrario: "",

        sheetDipartimento: false,
        searchDipartimento: "",

        dipartimentiTemp: [],
        dipartimenti: [],

        aziendeclientiTemp: [],

        utentiassociatiTemp: [],
        tipidocumento: [],
        tipolaurea: [],
        contratti: [],
        interviste: [],
        tiporiscontro: [],
        tipocontratto: [],
        sedelavorativa: [],
        sediassunzioneTemp: [],
        qualifica: [],
        ebadgetiporegistrazione: [],
        valoripianoorario: [],
        pianiorarioTemp: [],


        risNomeSede: "",

        risorsa: { companyid: "", departmentid: "", codice_piano_orario: "", view_overtime: "S", is_ebadge: "N", ebadge_regtype_qrcode: "N", ebadge_regtype_manual: "N", ebadge_regtype_reception: "N", idebadge_type_registration: "", is_enable_rnd_down_hh_ebadge: "N", id_group_to_associate: "" },

        attachRemove: false,

        sheetRisorse: false,

        fidoAnt: null,
        fidoCassa: null,

        visDescrizione: false,
        visNMatricola: false,
        visOP: false,
        visSw: false,

        visNSim: false,
        visTelNum: false,

        visTarga: false,
        visVeicolo: false,

        visTipoDisco: false,

        visPollici: false,



        sheetGruppi: false,

        searchGruppi: "",

        tipo_cespiti: [],

        risorse: [],

        banca: {},

        cespiti: {},

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ],

        tipi_dischi: [{
            "id": "RPM",
            "value": "RPM"
        },
        {
            "id": "SSD",
            "value": "SSD"
        }
        ]

    }),

    methods: {

        enableSaveButton: function () {

            var result = 1;

            if (this.risorsa.companyid == "") {
                result = 0;
            }

            if (result == 1) {
                if (this.risorsa.departmentid == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.risorsa.codice_piano_orario == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.risorsa.view_overtime == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.risorsa.is_ebadge == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.risorsa.is_ebadge == "S") {
                    if (this.risorsa.ebadge_regtype_qrcode == "") {
                        result = 0;
                    }
                }
            }

            if (result == 1) {
                if (this.risorsa.is_ebadge == "S") {
                    if (this.risorsa.ebadge_regtype_manual == "") {
                        result = 0;
                    }
                }
            }

            if (result == 1) {
                if (this.risorsa.is_ebadge == "S") {
                    if (this.risorsa.ebadge_regtype_reception == "") {
                        result = 0;
                    }
                }
            }

            if (result == 1) {
                if (this.risorsa.is_ebadge == "S") {
                    if (this.risorsa.idebadge_type_registration == "") {
                        result = 0;
                    }
                }
            }

            if (result == 1) {
                if (this.risorsa.id_group_to_associate == "") {
                    result = 0;
                }
            }


            return result;
        },


        loadPianoOrario: async function (id_azienda) {


            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirisorse.pianoOrarioAzienda(
                id_azienda,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from pianoOrarioAzienda", res);

                this.pianiorario = res.data.piano_orario;

                that.sheetPianoOrario = true;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );



        },


        manageClickPianoOrario: async function (pianoorario) {

            console.log("PO SEL: ", pianoorario);

            this.sheetPianoOrario = false;

            this.risorsa.codice_piano_orario = pianoorario.codice;


            this.setupButtons();


        },


        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },


        initDDL: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirisorse.initCreateResFromUser(
                v_token,
                window.$cookies.get("sel_filter_azienda_utenti")
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initCreateResFromUser", res);

                this.aziende = res.data.our_company;

                this.dipartimentiTemp = res.data.department;

                this.aziendeclientiTemp = res.data.customer;

                this.utentiassociatiTemp = res.data.users;

                this.tipidocumento = res.data.document_type;

                this.tipolaurea = res.data.tipo_laurea;

                this.contratti = res.data.tipo_contratto;

                this.interviste = res.data.interview;

                this.tiporiscontro = res.data.feedback;

                this.tipocontratto = res.data.tipo_contratto;

                this.sedelavorativa = res.data.allLocations;

                this.sediassunzioneTemp = res.data.sedi_assunzione;

                this.qualifica = res.data.qualifica;

                this.ebadgetiporegistrazione = res.data.ebadge_type_registration;

                this.valoripianoorario = res.data.piano_orario;

                this.pianiorarioTemp = res.data.piano_orario;

                this.gruppi = res.data.gruppi;

                setTimeout(() => {

                    var id_azi = window.$cookies.get("sel_filter_azienda_utenti");

                    this.risorsa.companyid = id_azi;

                    this.setDipartimenti(id_azi);


                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        manageClickDipartimenti: async function (dipartimento) {

            console.log("DIP SEL: ", dipartimento);

            this.sheetDipartimento = false;

            this.risorsa.departmentid = dipartimento.departmentid;

            this.setupButtons();

        },



        manageClickRisorse: async function (risorsa) {

            console.log("RIS SEL: ", risorsa);

            this.sheetRisorse = false;

            this.cespiti.idresource = risorsa.resourceid;

            this.syncOfficeRes(risorsa.resourceid);

        },




        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.risorsa.companyid = azienda.companyid;

            this.setDipartimenti(azienda.companyid);


            this.setupButtons();


        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                var v_result = this.enableSaveButton();

                if (v_result == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30,

                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35

                });




                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },

        validate() {

            var that = this;

            console.log("CESPITI: ", $("#idassettype").val());

            var errore = "";

            if (($("#idassettype").val() == 1) || ($("#idassettype").val() == 4) || ($("#idassettype").val() == 2) || ($("#idassettype").val() == 3)) {

                if ($("#description").val() == "") {

                    errore = "Specificare la descrizione";

                }

                if (errore == "") {

                    if ($("#n_matricola").val() == "") {

                        errore = "Specificare il numero matricola";

                    }

                }


                if (errore == "") {

                    if ($("#op_system").val() == "") {

                        errore = "Specificare il sistema operativo";

                    }

                }

                if (errore == "") {

                    if ($("#software").val() == "") {

                        errore = "Specificare il software";

                    }

                }

            }


            if (($("#idassettype").val() == 6) || ($("#idassettype").val() == 5)) {

                if ($("#num_sim").val() == "") {

                    errore = "Specificare il numero della sim";

                }

                if (errore == "") {

                    if ($("#tel_num").val() == "") {

                        errore = "Specificare il numero di telefono";

                    }

                }

            }


            if ($("#idassettype").val() == 7) {

                if ($("#targa").val() == "") {

                    errore = "Specificare la targa";

                }

                if (errore == "") {

                    if ($("#veicolo").val() == "") {

                        errore = "Specificare il veicolo";

                    }

                }

            }





            if (errore == "") {

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },

        saveData: async function () {

            var that = this;

            var id_user_sel = window.$cookies.get("sel_id_users_fro_create_res");


            console.log("ID USERS: ", id_user_sel);

            console.log("RISORSA: ", that.risorsa);


            // var that = this;

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apiusers.createResources(
                v_token,
                id_user_sel,
                that.risorsa
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createResources", res);

                that.$swal({
                    icon: "success",
                    text: "Risorse create correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/users"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );










        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncAziende", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncMailGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncMailGroup", res);

                this.gruppi = res.data.invoice_mail_group;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncInitInsert: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);


                this.tipo_cespiti = res.data.asset_type;

                this.risorse = res.data.resource;



                // this.aziende = res.data.aziende;

                // this.gruppi = res.data.invoice_mail_group;

                // this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncOfficeRes: async function (id_resource) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.getOfficeRes(
                v_token,
                id_resource
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getOfficeRes", res);

                this.risNomeSede = res.data.DescOffice;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncAsset: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.initEdit(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEdit", res);


                this.tipo_cespiti = res.data.asset_type;

                this.risorse = res.data.resource;

                this.cespiti = res.data.asset;

                this.cespiti.idasset_type = res.data.asset.idassettype;

                this.cespiti.tipo_disco = res.data.asset.tipo_disco;

                this.cespiti.pollici = res.data.asset.schermo_pollici;

                $("#itemIdAssetType").addClass("item-has-value");

                $("#itemRisorsa").addClass("item-has-value");

                this.syncOfficeRes(res.data.asset.idresource);


                if (res.data.asset.filename != null) {

                    $('.dropify-render').append("<i class='dropify-font-file'></i>");
                    $('.dropify-render').append("<span class='dropify-extension'>pdf</span>");

                    // dropify-font-file
                    $(".dropify-wrapper").addClass("has-preview");
                    $(".dropify-preview").show("slow");

                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncBanca: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getBanca(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getBanca", res);

                this.aziende = res.data.aziende;

                this.gruppi = res.data.invoice_mail_group;

                this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.itemRisorsa {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetRisorse {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}





.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-users {
    width: 700px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.createResFromUser {
    overflow-x: hidden;
}

.createResFromUser .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.createResFromUser .v-input {
    font-size: 1.2em !important;
}

.createResFromUser .v-select {
    font-size: 1.2em !important;
}

.createResFromUser .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

.headerSheetDipartimento {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemDipartimento {
    margin-left: 15px;
}

.headerSheetPianoOrario {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.first_column_piano_orario {
    width: 4%;
    text-align: left;
}

.second_column_piano_orario {
    width: 32%;
    text-align: left;
    font-weight: bold;
    font-size: 10px;
}

.third_column_piano_orario {
    width: 32%;
    text-align: left;
    margin-left: -6px;
    font-weight: bold;
    font-size: 10px;
}

.fourth_column_piano_orario {
    margin-left: -5px;
    font-weight: bold;
    font-size: 10px;
}

.itemPianoOrarioCodice {
    margin-left: 15px;
}

.itemPianoOrarioDescrizione {
    margin-left: 15px;
}


@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
