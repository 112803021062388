<template>
    <v-container class="w-container-users v-overflow editUtente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:700px;" v-model="valid" ref="form" lazy-validation>

            <v-col cols="12" sm="12" md="12">
                <v-text-field id='nome' ref="inputname" v-model="utente.nome" label="Nome" required :rules="nameRules">
                </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
                <v-text-field id='cognome' v-model="utente.cognome" label="Cognome" required :rules="surnameRules">
                </v-text-field>
            </v-col>


            <v-col cols="12" sm="12" md="12">

                <div id="cont_companyid" style="cursor:pointer">
                    <ion-item id="itemAzienda"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Azienda </ion-label>

                        <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text=""
                            :value="utente.companyid" interface="action-sheet" disabled readonly>

                            <ion-select-option v-for="item in aziende" :key="item.companyid" :value='item.companyid'> {{
                                item.fld_name
                            }}</ion-select-option>

                        </ion-select>
                    </ion-item>
                </div>

            </v-col>



            <v-col cols="12" sm="12" md="12">
                <v-text-field id='email' v-model="utente.email" label="Mail" required :rules="emailRules">
                </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
                <v-text-field id='email' v-model="utente.emailcc" label="CC" required :rules="emailCCRules">
                </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
                <v-text-field id='titolo' v-model="utente.titolo" label="Titolo" required
                    :rules="titleRules"></v-text-field>
            </v-col>



        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <div class="contpopupimporto">

            <template>
                <modal name="popupImporto" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Massimale</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreMassimale" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldMassimale" id="fldMassimale"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-massimale" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-massimale" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import apitimesheet from "../utils/timesheet/apitimesheet";

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {


        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;


        this.setupButtons();


        setTimeout(() => {

            this.syncAziende();

        }, 100);




        if (this.$route.params.id == 0) {

            this.$root.$children[0].curTitle = "Aggiungi Utente";


        }

        if (this.$route.params.id > 0) {



            this.changePsw = true;

        }

        if (this.$route.params.id > 0) {

            this.$root.$children[0].curTitle = "Modifica Utente";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/verificaTimesheet"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });


        });



        // titolo di default

        var yearSel = window.$cookies.get("year_sel_invite");
        var monthSel = window.$cookies.get("month_sel_invite");


        var descMonth = "";

        if (monthSel == 1) {
            descMonth = "Gennaio";
        }
        if (monthSel == 2) {
            descMonth = "Febbraio";
        }
        if (monthSel == 3) {
            descMonth = "Marzo";
        }
        if (monthSel == 4) {
            descMonth = "Aprile";
        }
        if (monthSel == 5) {
            descMonth = "Maggio";
        }
        if (monthSel == 6) {
            descMonth = "Giugno";
        }
        if (monthSel == 7) {
            descMonth = "Luglio";
        }
        if (monthSel == 8) {
            descMonth = "Agosto";
        }
        if (monthSel == 9) {
            descMonth = "Settembre";
        }
        if (monthSel == 10) {
            descMonth = "Ottobre";
        }
        if (monthSel == 11) {
            descMonth = "Novembre";
        }
        if (monthSel == 12) {
            descMonth = "Dicembre";
        }


        this.utente.titolo = "Timesheet " + descMonth + " " + yearSel.toString();



    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        nomeCorrente: "",

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "Invito Clienti/Fornitori",
        currDipendenteObj: {},



        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],


        emailCCRules: [
            (v) => !!v || "CC richiesta",
            (v) => /.+@.+\..+/.test(v) || "CC non valida",
        ],


        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],


        titleRules: [
            (v) => !!v || "Titolo richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.utente.companyid = azienda.companyid;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/conferma-prjweb.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    title: "Conferma",
                    width: 30

                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    width: 35,

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("UTENTE: ", that.utente);

            //console.log("COMPANYID: ", $("#companyid").val());

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                var errore = "";

                if ($("#companyid").val() == "") {
                    errore = "Specificare l'azienda";
                }


                if (errore == "") {

                    that.saveData();


                }
                else {

                    that.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }









            }
        },

        saveData: async function () {

            var that = this;

            console.log("UTENTE: ", that.utente);

            var v_token = window.$cookies.get("token");
            var v_ids = window.$cookies.get("id_users_sel_invite");

            var response = null;

            var username = window.$cookies.get("username");
            var body = "";

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            this.nomeCorrente = that.utente.email;

            response = await apitimesheet.invitoClientiFornitori(
                this.nomeCorrente,
                v_token,
                username,
                that.utente.titolo,
                body,
                0,
                null,
                that.utente.email,
                that.utente.nome,
                that.utente.cognome,
                1,
                v_ids,
                that.utente.companyid,
                window.$cookies.get("year_sel_invite"),
                window.$cookies.get("month_sel_invite"),
                that.utente.emailcc
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from invitoClientiFornitori", res);

                that.$swal({
                    icon: "success",
                    text: "L'invito è stato mandato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/verificaTimesheet"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );












        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtenteNew(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtenteNew", res);

                // try {

                //     this.aziende = res.data.aziende;
                //     this.prefissi = res.data.prefix;
                //     this.gruppi = res.data.models;
                //     this.utente = res.data.curr_user;

                //     this.utente.codice_fiscale = res.data.val_codice_fiscale;

                //     if (res.data.curr_user.models != null) {

                //         var grp_selected = res.data.curr_user.models.split(",");

                //         console.log("GRP SEL: ", grp_selected);

                //         if (grp_selected.length > 0) {

                //             for (var i = 0; i < this.gruppi.length; i++) {

                //                 if (this.gruppi[i] !== undefined) {

                //                     for (var x = 0; x < grp_selected.length; x++) {

                //                         console.log("GRP SEL 2: ", grp_selected[x]);

                //                         if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                //                             this.gruppi[i].checked = this.gruppi[i].id;

                //                         }

                //                     }

                //                 }

                //             }

                //         }


                //     }




                //     $("#itemAzienda").addClass("item-has-value");


                //     console.log("USER INFO EASYER: ", res.data.info_easyer);


                //     if (res.data.info_easyer != null) {

                //         this.utente.enable_easyer = res.data.info_easyer.enable;

                //         this.utente.max_easyer = res.data.info_easyer.max_value;

                //         this.utente.datestart_easyer = res.data.info_easyer.period_from;

                //         this.utente.dateend_easyer = res.data.info_easyer.period_to;


                //     }






                //     //console.log("ok");

                // } catch (error) {
                //     console.log(error);
                // }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteApi", res);

                try {

                    this.aziende = res.data.aziende;



                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-users {
    width: 700px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editUtente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editUtente .v-input {
    font-size: 1.2em !important;
}

.editUtente .v-select {
    font-size: 1.2em !important;
}

.editUtente .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
