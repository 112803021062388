<template>

    <div class="wrapperHome">

        <div class="cont-home" style="max-width:1035px">



            <div v-if="viewEmt" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px;cursor:pointer" class="iconEmt" contain
                    src="@/assets/EMT.png" transition="scale-transition" />

            </div>

            <div v-if="viewDoxweb" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/DOXWEB.png"
                    transition="scale-transition" />

            </div>

            <div v-if="viewTodolist" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/TODOLIST.png" transition="scale-transition" />

            </div>

            <div v-if="viewEBadge" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/EBADGE.png"
                    transition="scale-transition" />

            </div>

            <div v-if="viewInvoice" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/invoice.png"
                    transition="scale-transition" />

            </div>

            <div v-if="viewPrjweb" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/PRJ WEB.jpg"
                    transition="scale-transition" />

            </div>

            <div v-if="viewSmartinout" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/SMARTINOUT.jpg" transition="scale-transition" />

            </div>

            <div v-if="viewHdTool" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/HDTOOL.png"
                    transition="scale-transition" />

            </div>

            <div v-if="viewAlertMsg" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/ALERT MSG.jpg" transition="scale-transition" />

            </div>

            <div v-if="viewEasyER" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/easyer.png"
                    transition="scale-transition" />

            </div>

            <div v-if="view2Recruit" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/2Recruit2.png" transition="scale-transition" />

            </div>

            <div v-if="viewSendMagic" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/Sendmagic.png" transition="scale-transition" />

            </div>

            <div v-if="viewSmartTransfer" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/Smartransfer.png" transition="scale-transition" />

            </div>

            <div v-if="viewCostiLavoro" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/Costi Lavoro.png" transition="scale-transition" />

            </div>

            <div v-if="viewSmartBook" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/Smart book.png" transition="scale-transition" />

            </div>

            <div v-if="viewMyCheckList" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/Mychecklist.png" transition="scale-transition" />

            </div>

            <div v-if="viewDomainBoost" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/DOMAINBOOST.png" transition="scale-transition" />

            </div>


            <div v-if="viewItemng" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/ITEMNG.png"
                    transition="scale-transition" />

            </div>

            <div v-if="viewEasyMonitor" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/LENS.png"
                    transition="scale-transition" />

            </div>

            <div v-if="viewSmartAuth" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/SMARTAUTH.png" transition="scale-transition" />

            </div>

            <div v-if="viewSmartForm" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/SMARTFORM.png" transition="scale-transition" />

            </div>

            <div v-if="viewAlertduedate" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/ALERTDUEDATE.png" transition="scale-transition" />

            </div>

            <div v-if="viewDocSign" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain src="@/assets/DOCSIGN.jpg"
                    transition="scale-transition" />

            </div>

            <div v-if="viewEasyOnOff" class="block">

                <img alt="Emt logo" style="max-width:170px;min-width:170px" class=" " contain
                    src="@/assets/EASYONOFF.jpg" transition="scale-transition" />

            </div>




        </div>

    </div>


</template>

<script>

import apihome from "../utils/home/apihome";
import $ from 'jquery';

export default {
    name: "HelloWorld",

    mounted: function () {

        var pointerVue = this;


        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        this.isCustSupp = window.$cookies.get("is_cust_supp");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }




        this.loadLogo();


        this.loadInfoUserHome();

        this.loadConfUserHome();

        this.$root.$children[0].showButtonMenu = false;

        $(document).ready(function () {


            $('body').on('click', '.iconEmt', function () {

                console.log(("EVENTO CLICK ICONEMT"));

                console.log(("AZIENDA: ", pointerVue.idCompanyUser));

                if (pointerVue.idCompanyUser == 145) // agila
                {

                    var url_to_redirect = pointerVue.getUrlDomain("emt/home");

                    console.log(("URL: ", url_to_redirect));


                    window.open(url_to_redirect, "_blank");


                }

                if (pointerVue.idCompanyUser != 145) {

                    pointerVue.apriLinkVue(pointerVue.getUrlDomainVue(), 'emt', 'dash');

                }

                // apriLinkVue



            });




        });


    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },

    methods: {

        getUrlDomain: function (nome_element) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_element;

        },

        getUrlDomainVue: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//localhost:8080/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result;

        },

        apriLinkVue: function (domain_to_call, vue_element, comp_name) {

            console.log("domain_to_call", domain_to_call);
            console.log("vue_element", vue_element);
            console.log("comp_name", comp_name);

            var val_url = this.getUrlDomain("getCurrentUserVue");

            val_url = val_url + "?domain_to_call=" + domain_to_call + "&vue_element=" + vue_element + "&page_to_call=" + comp_name;

          

            console.log("VAL_URL: ", val_url);

            window.open(val_url, '_blank');

        },

        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "emtool")
                    .then((res) => {

                        console.log("res LoadLogoCompany: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;

                        // window.$cookies.set("user_models", res.data.Models, "9y");



                    });
            } catch (error) {
                console.log("err LoadLogoCompany", error);
            }

            console.log(response);



        },


        loadInfoUserHome: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apihome.getInfoHome(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoHome", res);

                that.$root.$children[0].companyName = res.data.azienda;
                that.$root.$children[0].companyAddress = res.data.indirizzo;
                that.$root.$children[0].companyPhone = res.data.telefono;
                that.$root.$children[0].showAddressCompany = true;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        decodString: function (value) {

            //console.log("VALUE: ", value);

            var substr = value.toString().substr(1, value.toString().length - 2);

            //console.log("VALUE 2: ", substr);

            var str_decod = atob(substr);

            //console.log("VALUE 3: ", str_decod);

            return str_decod;

        },


        loadConfUserHome: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apihome.getConfUser(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getConfUser", res);

                that.idCompanyUser = res.data.idcompany;

                const KEYS = Object.keys(res.data.configuration);

                Object.entries(KEYS).forEach(([key, value]) => {

                    var prod_name = that.decodString(value);

                    console.log('HOME PROD NAME: ', prod_name);
                    console.log('HOME PROD NAME: ', key);


                    // console.log('CHIAVE', ` ${key} ${value}` , res.data.configuration);

                    var sottovalori = res.data.configuration[value];


                    Object.entries(sottovalori).forEach(([key_s, value_s]) => {

                        //console.log ( "SOTTOVALORI: " + key_s, value_s, res.data.configuration );

                        var desc_perm = that.decodString(key_s);
                        var val_perm = that.decodString(value_s);

                        console.log("HOME DESC PERM: ", desc_perm);
                        console.log("HOME VALUE PERM: ", val_perm);


                        if (prod_name == "emt") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewEmt = true;
                                }
                            }
                        }

                        if (prod_name == "doxweb") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewDoxweb = true;
                                }
                            }
                        }


                        if (prod_name == "2dolist") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewTodolist = true;
                                }
                            }
                        }


                        if (prod_name == "ebadge") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewEBadge = true;
                                }
                            }
                        }

                        if (prod_name == "invoice") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewInvoice = true;
                                }
                            }
                        }

                        if (prod_name == "prjweb") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewPrjweb = true;
                                }
                            }
                        }

                        if (prod_name == "smartinout") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewSmartinout = true;
                                }
                            }
                        }

                        if (prod_name == "hdtool") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewHdTool = true;
                                }
                            }
                        }


                        if (prod_name == "alertmsg") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewAlertMsg = true;
                                }
                            }
                        }

                        if (prod_name == "easyer") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewEasyER = true;
                                }
                            }
                        }

                        if (prod_name == "2recruit") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.view2Recruit = true;
                                }
                            }
                        }

                        if (prod_name == "sendmagic") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewSendMagic = true;
                                }
                            }
                        }

                        if (prod_name == "smarttransfer") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewSmartTransfer = true;
                                }
                            }
                        }

                        if (prod_name == "costilavoro") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewCostiLavoro = true;
                                }
                            }
                        }

                        if (prod_name == "smartbook") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewSmartBook = true;
                                }
                            }
                        }

                        if (prod_name == "mychecklist") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewMyCheckList = true;
                                }
                            }
                        }

                        if (prod_name == "domainboost") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewDomainBoost = true;
                                }
                            }
                        }

                        if (prod_name == "itemng") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewItemng = true;
                                }
                            }
                        }

                        if (prod_name == "easymonitor") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewEasyMonitor = true;
                                }
                            }
                        }

                        if (prod_name == "smartauth") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewSmartAuth = true;
                                }
                            }
                        }

                        if (prod_name == "smartform") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewSmartForm = true;
                                }
                            }
                        }

                        if (prod_name == "alertduedate") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewAlertduedate = true;
                                }
                            }
                        }

                        if (prod_name == "docsign") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewDocSign = true;
                                }
                            }
                        }

                        if (prod_name == "easyonoff") {
                            if (desc_perm == "view_in_home") {
                                if (val_perm == 1) {
                                    that.viewEasyOnOff = true;
                                }
                            }
                        }



                    });


                });


                // console.log("CONF LEN: ", res.data.configuration);


                // console.log("CONF LEN 2: ", propertyNames);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



    },




    data: () => ({


        viewEmt: false,
        viewDoxweb: false,
        viewTodolist: false,
        viewEBadge: false,
        viewInvoice: false,
        viewPrjweb: false,
        viewSmartinout: false,
        viewHdTool: false,
        viewAlertMsg: false,
        viewEasyER: false,
        view2Recruit: false,
        viewSendMagic: false,
        viewSmartTransfer: false,
        viewCostiLavoro: false,
        viewSmartBook: false,
        viewMyCheckList: false,
        viewDomainBoost: false,
        viewItemng: false,
        viewEasyMonitor: false,
        viewSmartAuth: false,
        viewSmartForm: false,
        viewAlertduedate: false,
        viewDocSign: false,
        viewEasyOnOff: false,

        idCompanyUser: "",

        v_array: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],

        isAdmin: 0,
        isSU: 0,
        isCustSupp: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.wrapperHome {
    min-width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding-left: 85px;
    padding-right: 85px;
    /* background: yellow; */
}

.row.address {
    position: fixed;
    z-index: 4;
    bottom: 225px;
    right: 5px;
    min-width: 200px;
    display: flex;
    justify-content: start;
}

.cont-home {
    /* display: flex; */
    /* background: red; */
    flex-wrap: wrap;
    max-height: 100px;
    padding-top: 36px;
    /* padding-left: 85px; */
    min-width: 100vh;
    /* border: 1px solid; */
    /* padding-right: 85px; */
    align-content: flex-start;
    /* margin: 0 auto; */
    /* justify-content: flex-start; */
    max-height: calc(100vh - 169px);
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(82px, 168px));
    margin: 0 auto;
    justify-content: center;
}

.block {
    min-width: 170px;
    /* border: 1px solid; */
    /* margin-right: 10px; */
    margin-bottom: 10px;
    min-height: 170px;
    max-height: 170px;
    flex: 0 0 50px;
}

.block:last-child {
    align-self: flex-end;
    /* background-color: crimson; */
}

.btnText {
    padding-top: 20px;
    visibility: hidden;
}

@media only screen and (max-width: 600px) {

    .cont-home {
        display: flex;
        /* background: red; */
        flex-wrap: wrap;
        /* width: 500px !important; */
        max-height: 87vh;
        padding-top: 36px;
        /* padding-left: 85px; */
        min-width: 100vw !important;
        border: 1px solid;
        /* padding-right: 85px; */
        /* flex-direction: column; */
        align-content: flex-start;
        margin: 0 auto;
        justify-content: center;
        padding: 0;
    }

    .wrapperHome {
        min-width: 100vw;
        min-height: 100vh;
        margin: 0 auto;
        padding-left: 0px;
        padding-right: 0px;
        /* background: yellow; */
    }
}
</style>
