import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIGroups {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getAziendeGlobal(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("EmtGetAziendeGlobalApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsert(user, prodotto)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("prodotto",  prodotto);
    
   
    return axios.post(this.getUrlDomain("EmtInitAddGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async saveGroup(gruppo, user, id, ids_users, mails)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("ids_users",  ids_users);
    formData.append("mails",  mails);

    for ( var key in gruppo ) {
      formData.append(key, gruppo[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtSaveGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async initEdit(id, user, prodotto)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("prodotto",  prodotto);
    
    return axios.post(this.getUrlDomain("EmtInitEditGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteMail(sel_mails, ids)
  {

    let formData = new FormData();

    formData.append("v_sel_mails",  sel_mails);
    formData.append("v_ids",  ids);
    
    return axios.post(this.getUrlDomain("EmtEditGruppoDeleteMailApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async deleteGruppo(id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
   
    return axios.post(this.getUrlDomain("EmtDeleteGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APIGroups({
  url: "https://services.ebadge.it/public/api/"
})
