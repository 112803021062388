<template>
    <v-container class="w-container-filter-users v-overflow filter_mail_cedolini"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);overflow-x:hidden">
    </v-container>
</template>

<script>


import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        router.push({
            path: "/caricaCedoliniCud"
        });


    },


    data: () => ({

        selMese: "",


        mesi: [],


        viewMonths: false,

        viewYear: false,


        anni: [],

        tipiDoc: [
            {
                id: "Cedolini",
                description: "Cedolini"
            },
            {
                id: "Cud",
                description: "Cud"
            }
        ],


        selTipo: "",

        selAnno: "",

        titolo: "Mail Pay Slips",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),


})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-filter-users {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.filter_mail_cedolini .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

/* .filter_mail_cedolini .v-input {
    font-size: 1.2em;
}

.filter_mail_cedolini .v-select {
    font-size: 1.2em;
}

.filter_mail_cedolini .v-label {
    font-size: 1em;
} */



.filter_mail_cedolini .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.filter_mail_cedolini .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.filter_mail_cedolini ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.w-container-filter-users .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}




@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-filter-users {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
