<template>
    <v-container class="w-container-edit-pratica v-overflow edit_pratica_agila" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <div class="noDevMobile">

                <v-row>

                    <v-col cols="3" md="3">

                        <div id="cont_tipo_progetto_id">
                            <ion-item id="itemTipoProgetto">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold;opacity:1"
                                    position="floating">Tipo Progetto </ion-label>

                                <ion-select id="tipo_progetto_id" name="tipo_progetto_id" class='tipo_progetto_id'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.tipo_progetto_id"
                                    interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in tipo_progetto" :key="item.id" :value='item.id'> {{
                                        item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                    <v-col cols="3" md="3" v-if="showNoNomePratica">
                        &nbsp;
                    </v-col>

                    <v-col cols="3" md="3" v-if="showNomePratica" style="margin-top: 10px!important">
                        <v-text-field id="nome_pratica" v-model="pratica.nome" label="Pratica" readonly></v-text-field>
                    </v-col>

                    <v-col cols="3" md="3" v-if="showNoAnnoNomePratica">
                        &nbsp;
                    </v-col>


                    <v-col cols="3" md="3" v-if="showAnnoNomePratica">

                        <div id="cont_anno">
                            <ion-item id="itemTipoProgetto">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold;opacity:1"
                                    position="floating">Anno </ion-label>

                                <ion-select id="anno" name="anno" class='anno' cancel-Text="Chiudi" done-Text=""
                                    :value="pratica.anno" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in anni" :key="item.id" :value='item.id'> {{
                                        item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                    <v-col cols="3" md="3" v-if="showNoNumeroTotPratiche">
                        &nbsp;
                    </v-col>


                    <v-col cols="3" md="3" v-if="showNumeroTotPratiche" style="margin-top: 10px!important">

                        <v-text-field id="nome_pratica" class="centered-input" v-model="pratica.num_tot_pratiche"
                            label="N. Pratiche Totali" readonly></v-text-field>


                    </v-col>

                </v-row>


                <v-row>

                    <v-col cols="3" md="3">

                        <div id="cont_companyid">
                            <ion-item id="itemCompany">

                                <ion-label
                                    style="font-size:11px!important;color:black!important;font-weight:bold!important;opacity:1"
                                    position="floating">Azienda </ion-label>

                                <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                    done-Text="" :value="pratica.companyid" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in aziende" :key="item.companyid"
                                        :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>


                    <v-col cols="3" md="3">

                        <div id="cont_refid">
                            <ion-item id="itemReferente">

                                <ion-label
                                    style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                    position="floating">Referente </ion-label>

                                <ion-select id="referenteid" name="referenteid" class='referenteid' cancel-Text="Chiudi"
                                    done-Text="" :value="pratica.referenteid" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in referenti" :key="item.id" :value='item.id'> {{
                                        item.referente
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                    <v-col cols="3" md="3">

                        <v-col cols="12" md="12">

                            <div id="cont_gestid">
                                <ion-item id="itemGestore" style="margin-top:-12px!important">

                                    <ion-label
                                        style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                        position="floating">Gestore </ion-label>

                                    <ion-select id="login" name="login" class='login' cancel-Text="Chiudi" done-Text=""
                                        :value="pratica.login" interface="action-sheet" disabled readonly>

                                        <ion-select-option v-for="item in gestori" :key="item.login" :value='item.login'> {{
                                            item.name
                                        }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-col>


                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>


                <v-row v-if="showRow9">

                    <v-col cols="3" md="3">

                        <div id="cont_tipoaz">
                            <ion-item id="itemTipoAz">

                                <ion-label
                                    style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                    position="floating">Tipo di Azienda </ion-label>

                                <ion-select id="tipo_di_azienda" name="tipo_di_azienda" class='tipo_di_azienda'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.tipo_di_azienda"
                                    interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in tipo_azienda" :key="item.id" :value='item.id'> {{
                                        item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                    <v-col cols="3" md="3" style="margin-top: 10px!important">

                        <v-text-field id="sede_di_intervento" v-model="pratica.sede_di_intervento"
                            label="Sede di intervento" readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>

                <v-row v-if="showRow10">

                    <v-col cols="3" md="3">

                        <div id="cont_fin">
                            <ion-item id="itemFin"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label
                                    style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                    position="floating">Finanziamento </ion-label>

                                <ion-select id="finanziamento" name="finanziamento" class='finanziamento'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.finanziamento"
                                    interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in finanziamenti" :key="item.id" :value='item.id'>
                                        {{
                                            item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>



                    <v-col cols="3" md="3">

                        <div id="cont_fin">
                            <ion-item id="itemFin"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label
                                    style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                    position="floating">Tipo Finanziamento </ion-label>

                                <ion-select id="tipo_finanziamento" name="tipo_finanziamento" class='tipo_finanziamento'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.tipo_finanziamento"
                                    interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in tipi_finanziamento" :key="item.id" :value='item.id'>
                                        {{
                                            item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>



                <v-row v-if="showRow3">

                    <v-col ols="3" md="3">

                        <v-text-field id="username" v-model="pratica.username" label="Username" readonly>
                        </v-text-field>


                    </v-col>

                    <v-col ols="3" md="3">

                        <v-text-field id="password" v-model="pratica.password" label="Password" readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>

                <v-row v-if="showRow4">

                    <v-col ols="3" md="3">

                        <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field id="fldInpData" v-model="pratica.data_inoltro_domanda"
                                    label="Data inoltro domanda" persistent-hint readonly v-on="on" disabled></v-text-field>
                            </template>
                            <v-date-picker v-model="pratica.data_inoltro_domanda" no-title @input="showPicker3 = false"
                                locale="it">
                            </v-date-picker>
                        </v-menu>


                    </v-col>

                    <v-col ols="3" md="3">

                        <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field id="fldInpData" v-model="pratica.data_stipula_fin_leasing"
                                    label="Data stipula fin./leasing" persistent-hint readonly v-on="on"
                                    disabled></v-text-field>
                            </template>
                            <v-date-picker v-model="pratica.data_stipula_fin_leasing" no-title @input="showPicker4 = false"
                                locale="it">
                            </v-date-picker>
                        </v-menu>

                    </v-col>

                    <v-col ols="3" md="3">

                        <v-text-field id="cod_data_n_concessione" v-model="pratica.cod_data_n_concessione"
                            label="Cod/Data e N° Concessione" readonly>
                        </v-text-field>


                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>

                <v-row v-if="showRow1">

                    <v-col cols="3" md="3" style="margin-top: 10px!important">

                        <v-text-field id="chiusura_esercizio" v-model="pratica.chiusura_esercizio"
                            label="Chiusura Esercizio" readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>

                <v-row v-if="showRow2">

                    <v-col cols="3" md="3" style="margin-top: 10px!important">

                        <v-text-field id="dimensione_azienda" v-model="pratica.dimensione_azienda"
                            label="Dimensioni Azienda" readonly>
                        </v-text-field>

                    </v-col>

                    <v-col cols="3" md="3" style="margin-top: 10px!important">

                        <v-text-field id="dipendenti_azienda" v-model="pratica.dipendenti_azienda"
                            label="N. Dipendenti Azienda" readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="3" md="3">

                        <div id="d_previsionale"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Previsionale</label>

                                        <vue-autonumeric v-model="pratica.previsionale" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>

                    <v-col cols="3" md="3">

                        <v-text-field id="nickname_doxweb" v-model="pratica.nickname_doxweb" label="Nickname Doxweb"
                            readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>


                <v-row v-if="showRow11">

                    <v-col cols="3" md="3">

                        <!-- <v-text-field id="note" v-model="pratica.note" label="Note" readonly>
                        </v-text-field> -->

                        <!-- <v-menu v-model="showPicker6" :close-on-content-click="false" transitions="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field id="fldInpData" v-model="pratica.data_mandato" label="Data Mandato"
                                    persistent-hint readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="pratica.data_mandato" no-title @input="showPicker6 = false" locale="it" disabled>
                            </v-date-picker>
                        </v-menu> -->

                        <v-text-field id="fldInpData" v-model="pratica.data_mandato" label="Data Mandato" persistent-hint
                            readonly v-on="on"></v-text-field>


                    </v-col>

                    <v-col cols="3" md="3">

                        <div id="d_minmand"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Minimo Mandato</label>

                                        <vue-autonumeric v-model="pratica.minimo_mandato" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>


                </v-row>




                <v-row v-if="showRow12">

                    <v-col cols="3" md="3">

                        <v-text-field id="note" v-model="pratica.note" label="Note" readonly>
                        </v-text-field>

                    </v-col>

                    <v-col cols="3" md="3">

                        <div id="d_minmand"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Minimo Mandato</label>

                                        <vue-autonumeric v-model="pratica.minimo_mandato" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>

                    <v-col ols="3" md="3">

                        <v-menu v-model="showPicker5" :close-on-content-click="false" transitions="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field id="fldInpData" v-model="pratica.prima_data_ricerca_sviluppo"
                                    label="Prima Data Ricerca e Sviluppo" persistent-hint readonly disabled v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="pratica.prima_data_ricerca_sviluppo" no-title
                                @input="showPicker5 = false" locale="it">
                            </v-date-picker>
                        </v-menu>


                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>


                </v-row>





                <v-row v-if="showRow5">

                    <v-col cols="3" md="3" v-if="showCol1">

                        <div id="d_contr"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Contributo</label>

                                        <vue-autonumeric v-model="pratica.contributo" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>

                    <v-col ols="3" md="3">

                        <div id="d_inv"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Investimento</label>

                                        <vue-autonumeric v-model="pratica.investimento" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>

                    <v-col ols="3" md="3">

                        <div id="cont_mand">
                            <ion-item id="itemMand" style="margin-top:-9px">

                                <ion-label
                                    style="font-size:11px;color:black!important;font-weight:bold;opacity:1;cursor:pointer"
                                    position="floating">Mandato </ion-label>

                                <ion-select id="mandato" name="mandato" class='mandato' cancel-Text="Chiudi" done-Text=""
                                    :value="pratica.mandato" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in valori_sino" :key="item.id" :value='item.id'>
                                        {{
                                            item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>



                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>



                <v-row v-if="showRow7">

                    <v-col ols="3" md="3">

                        <v-text-field id="note" v-model="pratica.note" label="Note" readonly>
                        </v-text-field>

                        <!-- <v-text-field id="data_mandato" v-model="pratica.data_mandato" label="Data Mandato (GG/MM/AAAA)" readonly>
                        </v-text-field> -->


                    </v-col>

                    <v-col ols="3" md="3">

                        <div id="cont_intmand">
                            <ion-item id="itemIntMand" style="margin-top:-9px">

                                <ion-label
                                    style="font-size:11px;color:black!important;font-weight:bold;opacity:1;cursor:pointer"
                                    position="floating">Intestatario Mandato </ion-label>

                                <ion-select id="intestatario_mandato" name="intestatario_mandato"
                                    class='intestatario_mandato' cancel-Text="Chiudi" done-Text=""
                                    :value="pratica.intestatario_mandato" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in intestatari_mandato" :key="item.id" :value='item.id'>
                                        {{
                                            item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                    <v-col ols="3" md="3">

                        <div id="cont_status">
                            <ion-item id="itemStatus" style="margin-top:-9px">

                                <ion-label
                                    style="font-size:11px;color:black!important;font-weight:bold;opacity:1;cursor:pointer"
                                    position="floating">Stato </ion-label>

                                <ion-select id="id_pratica_stato" name="id_pratica_stato" class='id_pratica_stato'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.id_pratica_stato"
                                    interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in stati" :key="item.id" :value='item.id'>
                                        {{
                                            item.descrizione
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>


                </v-row>

                <v-row>

                    <v-col ols="3" md="3">

                        <v-text-field id="stato_fatturato" v-model="pratica.stato_fatturato" label="Stato Fatturato"
                            readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">

                        <v-text-field id="stato_pagamento" v-model="pratica.stato_pagamento" label="Stato Pagamento"
                            readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>

                <v-row v-if="showDateInsUpd">

                    <v-col ols="3" md="3">

                        <v-text-field id="data_inserimento" v-model="dataInserimento" label="Data Inserimento" readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">

                        <v-text-field id="data_ultima_modifica" v-model="dataUltimaModifica" label="Data Ultima Modifica"
                            readonly>
                        </v-text-field>

                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                    <v-col ols="3" md="3">
                        &nbsp;
                    </v-col>

                </v-row>

            </div>


            <div class="devMobile">

                <v-row>

                    <v-col cols="12" md="12">

                        <div id="cont_tipo_progetto_id">
                            <ion-item id="itemTipoProgetto"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label
                                    style="font-size:11px;color:black!important;font-weight:bold;opacity:1;cursor:pointer"
                                    position="floating">Tipo Progetto </ion-label>

                                <ion-select id="tipo_progetto_id" name="tipo_progetto_id" class='tipo_progetto_id'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.tipo_progetto_id"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in tipo_progetto" :key="item.id" :value='item.id'> {{
                                        item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>


                <v-row v-if="showNomePratica">

                    <v-col cols="12" md="12">
                        <v-text-field id="nome_pratica" v-model="pratica.nome" label="Pratica"
                            :readonly="readOnlyNomePratica"></v-text-field>
                    </v-col>

                </v-row>

                <v-row v-if="showAnnoNomePratica">

                    <v-col cols="12" md="12">

                        <div id="cont_anno">
                            <ion-item id="itemTipoProgetto"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label
                                    style="font-size:11px;color:black!important;font-weight:bold;opacity:1;cursor:pointer"
                                    position="floating">Anno </ion-label>

                                <ion-select id="anno" name="anno" class='anno' cancel-Text="Chiudi" done-Text=""
                                    :value="pratica.anno" interface="action-sheet">

                                    <ion-select-option v-for="item in anni" :key="item.id" :value='item.id'> {{
                                        item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                </v-row>

                <v-row v-if="showNumeroTotPratiche">

                    <v-col cols="12" md="12">

                        <v-text-field id="nome_pratica" class="centered-input" v-model="pratica.num_tot_pratiche"
                            label="N. Pratiche Totali" :readonly="readOnlyNumeroTotPratiche"></v-text-field>


                    </v-col>

                </v-row>

                <v-row>
                    <v-col cols="12" md="12">

                        <div id="cont_companyid">
                            <ion-item id="itemCompany"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label
                                    style="font-size:11px;color:black!important;font-weight:bold;opacity:1;cursor:pointer"
                                    position="floating">Azienda </ion-label>

                                <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                    done-Text="" :value="pratica.companyid" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in aziende" :key="item.companyid"
                                        :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>


                <v-row>

                    <v-col cols="12" md="12">

                        <div id="cont_refid">
                            <ion-item id="itemReferente"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold;opacity:1"
                                    position="floating">Referente </ion-label>

                                <ion-select id="referenteid" name="referenteid" class='referenteid' cancel-Text="Chiudi"
                                    done-Text="" :value="pratica.referenteid" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in referenti" :key="item.id" :value='item.id'> {{
                                        item.referente
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>


                <v-row>

                    <v-col cols="12" md="12">


                        <div id="cont_gestid">
                            <ion-item id="itemGestore"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold"
                                    position="floating">Gestore </ion-label>

                                <ion-select id="login" name="login" class='login' cancel-Text="Chiudi" done-Text=""
                                    :value="pratica.login" interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in gestori" :key="item.login" :value='item.login'> {{
                                        item.name
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>



                    </v-col>


                </v-row>

                <v-row v-if="showRow9">

                    <v-col cols="12" md="12">


                        <div id="cont_tipoaz">
                            <ion-item id="itemTipoAz"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold"
                                    position="floating">Tipo di Azienda </ion-label>

                                <ion-select id="tipo_di_azienda" name="tipo_di_azienda" class='tipo_di_azienda'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.tipo_di_azienda"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in tipo_azienda" :key="item.id" :value='item.id'> {{
                                        item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>



                    </v-col>


                </v-row>


                <v-row v-if="showRow9">

                    <v-col cols="12" md="12">


                        <v-text-field id="sede_di_intervento" v-model="pratica.sede_di_intervento"
                            label="Sede di intervento">
                        </v-text-field>


                    </v-col>


                </v-row>

                <v-row v-if="showRow10">

                    <v-col cols="12" md="12">

                        <div id="cont_fin">
                            <ion-item id="itemFin"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold"
                                    position="floating">Finanziamento </ion-label>

                                <ion-select id="finanziamento" name="finanziamento" class='finanziamento'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.finanziamento"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in finanziamenti" :key="item.id" :value='item.id'>
                                        {{
                                            item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>

                <v-row v-if="showRow10">

                    <v-col cols="12" md="12">

                        <div id="cont_fin">
                            <ion-item id="itemFin"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold"
                                    position="floating">Tipo Finanziamento </ion-label>

                                <ion-select id="tipo_finanziamento" name="tipo_finanziamento" class='tipo_finanziamento'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.tipo_finanziamento"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in tipi_finanziamento" :key="item.id" :value='item.id'>
                                        {{
                                            item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>

                <v-row v-if="showRow3">

                    <v-col ols="12" md="12">

                        <v-text-field id="username" v-model="pratica.username" label="Username">
                        </v-text-field>


                    </v-col>

                </v-row>


                <v-row v-if="showRow3">

                    <v-col ols="12" md="12">

                        <v-text-field id="password" v-model="pratica.password" label="Password">
                        </v-text-field>

                    </v-col>

                </v-row>


                <v-row v-if="showRow4">

                    <v-col ols="12" md="12">

                        <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field id="fldInpData" v-model="pratica.data_inoltro_domanda"
                                    label="Data inoltro domanda" persistent-hint readonly v-on="on"
                                    style="font-weight:bold"></v-text-field>
                            </template>
                            <v-date-picker v-model="pratica.data_inoltro_domanda" no-title @input="showPicker3 = false"
                                locale="it">
                            </v-date-picker>
                        </v-menu>



                    </v-col>

                </v-row>

                <v-row v-if="showRow4">

                    <v-col ols="12" md="12">

                        <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field id="fldInpData" v-model="pratica.data_stipula_fin_leasing"
                                    label="Data stipula fin./leasing" persistent-hint readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="pratica.data_stipula_fin_leasing" no-title @input="showPicker4 = false"
                                locale="it">
                            </v-date-picker>
                        </v-menu>

                    </v-col>

                </v-row>

                <v-row v-if="showRow4">

                    <v-col ols="12" md="12">

                        <v-text-field id="cod_data_n_concessione" v-model="pratica.cod_data_n_concessione"
                            label="Cod/Data e N° Concessione">
                        </v-text-field>


                    </v-col>

                </v-row>

                <v-row v-if="showRow1">

                    <v-col ols="12" md="12">

                        <v-text-field id="chiusura_esercizio" v-model="pratica.chiusura_esercizio"
                            label="Chiusura Esercizio" :readonly="chiusEsercReadOnly">
                        </v-text-field>


                    </v-col>

                </v-row>


                <v-row v-if="showRow2">

                    <v-col ols="12" md="12">

                        <v-text-field id="dimensione_azienda" v-model="pratica.dimensione_azienda"
                            label="Dimensioni Azienda" :readonly="dimAziReadOnly">
                        </v-text-field>


                    </v-col>

                </v-row>

                <v-row v-if="showRow2">

                    <v-col ols="12" md="12">

                        <v-text-field id="dipendenti_azienda" v-model="pratica.dipendenti_azienda"
                            label="N. Dipendenti Azienda" :readonly="dipAziReadOnly">
                        </v-text-field>


                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <div id="d_previsionale"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Previsionale</label>

                                        <vue-autonumeric v-model="pratica.previsionale" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>


                </v-row>


                <v-row>

                    <v-col cols="12" md="12">

                        <v-text-field id="nickname_doxweb" v-model="pratica.nickname_doxweb" label="Nickname Doxweb"
                            :readonly="nickDoxReadonly">
                        </v-text-field>

                    </v-col>


                </v-row>


                <v-row v-if="showRow11">

                    <v-col cols="12" md="12">

                        <v-text-field id="note" v-model="pratica.note" label="Note">
                        </v-text-field>

                    </v-col>


                </v-row>


                <v-row v-if="showRow11">

                    <v-col cols="12" md="12">

                        <div id="d_minmand"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Minimo Mandato</label>

                                        <vue-autonumeric v-model="pratica.minimo_mandato" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>

                </v-row>


                <v-row v-if="showRow12">

                    <v-col cols="12" md="12">

                        <v-text-field id="note" v-model="pratica.note" label="Note">
                        </v-text-field>

                    </v-col>

                </v-row>


                <v-row v-if="showRow12">

                    <v-col cols="12" md="12">

                        <div id="d_minmand"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Minimo Mandato</label>

                                        <vue-autonumeric v-model="pratica.minimo_mandato" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </v-col>

                </v-row>


                <v-row v-if="showRow12">

                    <v-col cols="12" md="12">

                        <v-menu v-model="showPicker5" :close-on-content-click="false" transitions="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field id="fldInpData" v-model="pratica.prima_data_ricerca_sviluppo"
                                    label="Prima Data Ricerca e Sviluppo" persistent-hint readonly v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="pratica.prima_data_ricerca_sviluppo" no-title
                                @input="showPicker5 = false" locale="it">
                            </v-date-picker>
                        </v-menu>

                    </v-col>

                </v-row>

                <v-row v-if="showRow5 && showCol1">


                    <v-col cols="12" md="12">

                        <div id="d_contr"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Contributo</label>

                                        <vue-autonumeric v-model="pratica.contributo" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </v-col>



                </v-row>


                <v-row v-if="showRow5">


                    <v-col cols="12" md="12">

                        <div id="d_inv"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Investimento</label>

                                        <vue-autonumeric v-model="pratica.investimento" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </v-col>



                </v-row>


                <v-row v-if="showRow5">


                    <v-col cols="12" md="12">

                        <div id="cont_mand">
                            <ion-item id="itemMand"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold"
                                    position="floating">Mandato </ion-label>

                                <ion-select id="mandato" name="mandato" class='mandato' cancel-Text="Chiudi" done-Text=""
                                    :value="pratica.mandato" interface="action-sheet">

                                    <ion-select-option v-for="item in valori_sino" :key="item.id" :value='item.id'>
                                        {{
                                            item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>



                    </v-col>



                </v-row>

                <v-row v-if="showRow7">


                    <v-col cols="12" md="12">

                        <v-text-field id="note" v-model="pratica.note" label="Note 2" readonly>
                        </v-text-field>

                        <!-- <v-text-field id="data_mandato" v-model="pratica.data_mandato" label="Data Mandato (GG/MM/AAAA)">
                        </v-text-field> -->


                    </v-col>



                </v-row>



                <v-row v-if="showRow7">


                    <v-col cols="12" md="12">

                        <div id="cont_intmand">
                            <ion-item id="itemIntMand" style="margin-top:-9px">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold"
                                    position="floating">Intestatario Mandato </ion-label>

                                <ion-select id="intestatario_mandato" name="intestatario_mandato"
                                    class='intestatario_mandato' cancel-Text="Chiudi" done-Text=""
                                    :value="pratica.intestatario_mandato" interface="action-sheet">

                                    <ion-select-option v-for="item in intestatari_mandato" :key="item.id" :value='item.id'>
                                        {{
                                            item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>



                    </v-col>



                </v-row>

                <v-row v-if="showRow7">


                    <v-col cols="12" md="12">

                        <div id="cont_status">
                            <ion-item id="itemStatus" style="margin-top:-9px">

                                <ion-label style="font-size:11px;color:black!important;font-weight:bold"
                                    position="floating">Stato </ion-label>

                                <ion-select id="id_pratica_stato" name="id_pratica_stato" class='id_pratica_stato'
                                    cancel-Text="Chiudi" done-Text="" :value="pratica.id_pratica_stato"
                                    interface="action-sheet" disabled readonly>

                                    <ion-select-option v-for="item in stati" :key="item.id" :value='item.id'>
                                        {{
                                            item.descrizione
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>


                <v-row>

                    <v-col ols="12" md="12">

                        <v-text-field id="stato_fatturato" v-model="pratica.stato_fatturato" label="Stato Fatturato"
                            :readonly="statoFatturatoReadOnly">
                        </v-text-field>

                    </v-col>


                </v-row>

                <v-row>

                    <v-col ols="12" md="12">

                        <v-text-field id="stato_pagamento" v-model="pratica.stato_pagamento" label="Stato Pagamento"
                            :readonly="statoPagamentoReadOnly">
                        </v-text-field>

                    </v-col>


                </v-row>

                <v-row v-if="showDateInsUpd">

                    <v-col ols="12" md="12">

                        <v-text-field id="data_inserimento" v-model="dataInserimento" label="Data Inserimento" readonly>
                        </v-text-field>

                    </v-col>


                </v-row>


                <v-row v-if="showDateInsUpd">

                    <v-col ols="12" md="12">

                        <v-text-field id="data_ultima_modifica" v-model="dataUltimaModifica" label="Data Ultima Modifica"
                            readonly>
                        </v-text-field>

                    </v-col>


                </v-row>



            </div>


        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopupposizione">

            <template>
                <modal name="popupPosizione" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Posizione</label>

                            <div id="d_posizione_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePosizione" :options="'integer'"
                                                ref="fldPosizione" id="fldPosizione"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-posizione" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelPosizione" />

                                <v-img alt="" class="btn-confirm-posizione" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmPosizione" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contreferente">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetReferenti" class="vreferentisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetReferenti" fixed style="position:fixed;">
                                Referenti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetReferenti = !sheetReferenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchRef">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredReferenti" :key="item.id"
                                        @click="manageClickReferenti(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemReferente'>{{ item.referente }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contgestore">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetGestori" class="vgestorisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetGestori" fixed style="position:fixed;">
                                Gestori
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetGestori = !sheetGestori">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchGest">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredGestori" :key="item.login"
                                        @click="manageClickGestori(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemGestore'>{{ item.name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>


        <div class="contpopupprevisionale">

            <template>
                <modal name="popupPrevisionale" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Previsionale</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePrevisionale" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldProroga" id="fldProroga"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-previsionale" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelPrevisionale" />

                                <v-img alt="" class="btn-confirm-previsionale" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmPrevisionale" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupminmand">

            <template>
                <modal name="popupMinMand" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Minimo Mandato</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreMinimoMandato" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldProroga" id="fldProroga"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-minmand" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelMinMand" />

                                <v-img alt="" class="btn-confirm-minmand" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmMinMand" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupinv">

            <template>
                <modal name="popupInv" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Investimento</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreInvestimento" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldProroga" id="fldProroga"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-inv" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelInv" />

                                <v-img alt="" class="btn-confirm-inv" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmInv" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupcontr">

            <template>
                <modal name="popupContr" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Contributo</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreContributo" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldProroga" id="fldProroga"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-contr" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelContr" />

                                <v-img alt="" class="btn-confirm-contr" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmContr" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetTipoContr">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetTipoContr = !sheetTipoContr">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Tipo Controllo
                    </div>
                    <v-list-item v-for="prj in tipoControllo" :key="prj.id" @click="filterTipoControllo(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.descrizione
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>




    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
import apigroups from "../utils/groups/apigroups";

import apipratiche from "../utils/pratiche/apipratiche";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        console.log("PARAMS: ", this.$route.params.id);

        console.log("AZIENDE: ", window.ddlAziende);


        var pointerVue = this;


        this.cur_id = this.decodeString(this.$route.params.id);

        this.setupButtons();


        this.aziende = window.ddlAziende;

        this.tipo_progetto = window.ddlTipoProgetto;

        this.praticheTemp = window.ddlPratiche;

        this.gruppi = window.ddlGruppi;

        this.tipo_controllo = window.ddlTipoControllo;

        this.referentiTemp = window.ddlReferenti;

        this.gestori = window.ddlGestori;

        this.stati = window.ddlStato;


        if (this.cur_id == 0) {

            $("#divTitStorico").hide();
            $("#divValStorico").hide();


            this.statoFatturatoReadOnly = false;
            this.pratica.stato_fatturato = "No";
            this.statoFatturatoReadOnly = true;

            this.statoPagamentoReadOnly = false;
            this.pratica.stato_pagamento = "No";
            this.statoPagamentoReadOnly = true;


            this.pratica.id_pratica_stato = 1; // Aperta

            this.pratica.mandato = "N";

        }

        if (this.cur_id > 0) {

            this.titolo = "Vedi Pratica";

            this.initEditGruppo();

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.saveData();

                    break;

                case "btn_back":
                    router.push({
                        path: "/pratiche"
                    });
                    break;

                case "btn_utenti":

                    window.$cookies.set("sel_filter_azienda_gruppo_utenti", $("#companyid").val(), "9y");
                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupSelUsers"
                    });


                    break;

                case "btn_mail":

                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupMails"
                    });

                    break;


                case "btn_referenti":


                    window.$cookies.set("sel_filter_referenti", this.pratica.companyid, "9y");

                    window.$cookies.set("is_from_edit_pratica", "S", "9y");

                    window.$cookies.set("id_edit_pratica", this.$route.params.id, "9y");


                    console.log("PRAT: ", this.pratica);

                    var n_pratica = this.pratica.nome;
                    if (this.pratica.anno != "") {

                        if (this.pratica.anno != undefined) {


                            n_pratica = n_pratica + " " + this.pratica.anno;


                        }


                    }

                    console.log("NOME PRAT: ", n_pratica);

                    window.$cookies.set("nome_pratica", n_pratica, "9y");



                    var nome_cliente = "";
                    for (var x = 0; x < this.aziende.length - 1; x++) {

                        if (parseInt(this.aziende[x].companyid) == parseInt(this.pratica.companyid)) {
                            nome_cliente = this.aziende[x].fld_name;
                        }

                    }


                    console.log("CLIENTE: ", nome_cliente);

                    window.$cookies.set("nome_cliente", nome_cliente, "9y");


                    router.push({
                        path: "/referenti"
                    });

                    break;

                case "btn_history_ctrls":

                    window.$cookies.set("id_edit_pratica", this.$route.params.id, "9y");

                    var n_pratica2 = this.pratica.nome;
                    if (this.pratica.anno != "") {


                        if (this.pratica.anno != undefined) {


                            n_pratica2 = n_pratica2 + " " + this.pratica.anno;


                        }


                    }

                    window.$cookies.set("nome_pratica", n_pratica2, "9y");

                    var nome_cliente2 = "";
                    for (var x2 = 0; x2 < this.aziende.length - 1; x2++) {

                        if (parseInt(this.aziende[x2].companyid) == parseInt(this.pratica.companyid)) {
                            nome_cliente2 = this.aziende[x2].fld_name;
                        }

                    }


                    console.log("CLIENTE: ", nome_cliente2);

                    window.$cookies.set("nome_cliente", nome_cliente2, "9y");

                    router.push({
                        path: "/historyControlli/" + this.$route.params.id
                    });



                    break;


                case "btn_filt_tipo_ctr":

                    this.sheetTipoContr = true;

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {


            // $('body').on('click', '#d_inv', function (event) {
            //     console.log(event);

            //     pointerVue.$modal.show('popupInv');

            // });



            // $('body').on('click', '#d_contr', function (event) {
            //     console.log(event);

            //     pointerVue.$modal.show('popupContr');

            // });

            // $('body').on('click', '#d_minmand', function (event) {
            //     console.log(event);

            //     pointerVue.$modal.show('popupMinMand');

            // });


            // $('body').on('click', '#d_previsionale', function (event) {
            //     console.log(event);

            //     pointerVue.$modal.show('popupPrevisionale');

            // });


            $('body').on('click', '#d_posizione', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPosizione');

            });


            $('body').on('ionChange', '.intestatario_mandato', function (event) {
                console.log(event);

                pointerVue.pratica.intestatario_mandato = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.mandato', function (event) {
                console.log(event);

                pointerVue.pratica.mandato = this.value;


            });





            $('body').on('ionChange', '.anno', function (event) {
                console.log(event);

                pointerVue.pratica.anno = this.value;

                setTimeout(() => {

                    pointerVue.updateNumTotPratiche();

                }, 200);


            });




            $('body').on('ionChange', '.tipo_progetto_id', function (event) {
                console.log(event);

                pointerVue.pratica.tipo_progetto_id = this.value;

                pointerVue.setTipoControllo(pointerVue.pratica.tipo_progetto_id);


                console.log("CUR YEAR: ", new Date().getFullYear());




                if (this.value != "") {


                    pointerVue.showNoNomePratica = false;
                    pointerVue.showNomePratica = true;

                    pointerVue.showNoAnnoNomePratica = true;
                    pointerVue.showAnnoNomePratica = false;

                    pointerVue.showNoNumeroTotPratiche = true;
                    pointerVue.showNumeroTotPratiche = false;

                    pointerVue.readOnlyNomePratica = false;

                    //   pointerVue.pratica.nome = "";

                    pointerVue.showRow9 = true;
                    pointerVue.showRow10 = true;
                    pointerVue.showRow11 = true;
                    pointerVue.showRow12 = false;


                    pointerVue.showCol1 = true;
                    pointerVue.showCol2 = true;


                    if (this.value == 1) // sabatini
                    {

                        pointerVue.showRow1 = false;
                        pointerVue.showRow2 = false;
                        pointerVue.showRow3 = true;
                        pointerVue.showRow4 = true;
                        //pointerVue.showRow5 = false;
                        // pointerVue.showRow7 = false;
                        //pointerVue.showRow6 = true;
                        // pointerVue.showRow8 = true;

                        pointerVue.showCol1 = false;
                        pointerVue.showCol2 = false;


                    }

                    if (this.value != 1) // non sabatini
                    {

                        pointerVue.showRow1 = true;
                        pointerVue.showRow2 = true;
                        pointerVue.showRow3 = false;
                        pointerVue.showRow4 = false;
                        pointerVue.showRow9 = false;
                        pointerVue.showRow10 = false;
                        //pointerVue.showRow5 = true;
                        //pointerVue.showRow6 = false;
                        // pointerVue.showRow7 = true;
                        // pointerVue.showRow8 = false;

                        if (this.value == 3) // ricerca e sviluppo
                        {
                            pointerVue.showRow11 = false;
                            pointerVue.showRow12 = true;

                        }

                        pointerVue.showNoAnnoNomePratica = false;
                        pointerVue.showNoNumeroTotPratiche = false;

                        pointerVue.showAnnoNomePratica = true;
                        pointerVue.showNumeroTotPratiche = true;

                        console.log("TIPO PROG: ", pointerVue.tipo_progetto);

                        var nome_pratica = "";
                        for (var x = 0; x <= pointerVue.tipo_progetto.length - 1; x++) {

                            if (parseInt(this.value) == parseInt(pointerVue.tipo_progetto[x].id)) {

                                nome_pratica = pointerVue.tipo_progetto[x].nome;

                            }


                        }

                        //   console.log("NOME PRATICA: ", nome_pratica);

                        pointerVue.pratica.nome = nome_pratica;

                        pointerVue.readOnlyNomePratica = true;

                        var v_anno = new Date().getFullYear();

                        pointerVue.pratica.anno = v_anno;

                        pointerVue.readOnlyNumeroTotPratiche = true;


                        setTimeout(() => {

                            pointerVue.updateNumTotPratiche();

                        }, 200);




                    }

                    pointerVue.setupButtons();

                }



            });

            $('body').on('ionChange', '.praticaid', function (event) {
                console.log(event);

                pointerVue.controllo.praticaid = this.value;

                // pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.id_tipo_controllo', function (event) {
                console.log(event);

                pointerVue.controllo.id_tipo_controllo = this.value;

                // pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.idresources_group_name', function (event) {
                console.log(event);

                pointerVue.controllo.idresources_group_name = this.value;

            });

            $('body').on('ionChange', '.status', function (event) {
                console.log(event);

                pointerVue.controllo.status = this.value;

            });

            $('body').on('blur', '#nickname_doxweb', function (event) {
                console.log(event);

                pointerVue.setupButtons();

            });

            $('body').on('blur', '#data_mandato', function (event) {
                console.log(event);

                pointerVue.setupButtons();

            });



            $('body').on('blur', '#nome_pratica', function (event) {
                console.log(event);

                pointerVue.setupButtons();

            });

            // $('body').on('click', '#cont_companyid', function (event) {
            //     console.log(event);

            //     pointerVue.sheetAziende = true;

            // });



            // $('body').on('click', '#cont_refid', function (event) {
            //     console.log(event);

            //     pointerVue.sheetReferenti = true;

            // });

            // $('body').on('click', '#cont_gestid', function (event) {
            //     console.log(event);

            //     pointerVue.sheetGestori = true;

            // });


            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

        });

    },

    computed: {


        filteredGestori() {

            return _.orderBy(this.gestori.filter(item => {
                if (!this.searchGest) return this.gestori;
                return (item.name.toLowerCase().includes(this.searchGest.toLowerCase()));
            }), 'headline')
        },



        filteredReferenti() {

            return _.orderBy(this.referenti.filter(item => {
                if (!this.searchRef) return this.referenti;
                return (item.referente.toLowerCase().includes(this.searchRef.toLowerCase()));
            }), 'headline')
        },



        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        showPicker6: false,

        sheetTipoContr: false,

        tipoControlloTemp: [],
        tipoControllo: [],

        showDateInsUpd: false,

        showCol1: true,
        showCol2: true,

        dipAziReadOnly: true,
        dimAziReadOnly: true,
        chiusEsercReadOnly: true,
        nickDoxReadonly: false,


        statoFatturatoReadOnly: true,
        statoPagamentoReadOnly: true,

        showRow1: true,
        showRow2: true,
        showRow3: false,
        showRow4: false,
        showRow5: true,
        showRow6: false,
        showRow7: true,
        showRow8: false,
        showRow9: true,
        showRow10: true,
        showRow11: true,
        showRow12: false,

        showPicker3: false,
        showPicker4: false,
        showPicker5: false,

        disStatus: true,


        stati: [],

        valoreInvestimento: null,

        valoreContributo: null,

        showNoNumeroTotPratiche: true,

        showNoAnnoNomePratica: true,

        showNoNomePratica: true,

        valoreMinimoMandato: null,


        valorePrevisionale: null,

        showNumeroTotPratiche: false,

        readOnlyNumeroTotPratiche: false,

        showAnnoNomePratica: false,

        valori_sino: [{ id: "S", nome: "Si" }, { id: "N", nome: "No" }],

        anni: [{ id: "2015", nome: "2015" }, { id: "2016", nome: "2016" }, { id: "2017", nome: "2017" }, { id: "2018", nome: "2018" }, { id: "2019", nome: "2019" }, { id: "2020", nome: "2020" }, { id: "2021", nome: "2021" }, { id: "2022", nome: "2022" }, { id: "2023", nome: "2023" }, { id: "2024", nome: "2024" }, { id: "2025", nome: "2025" }],


        tipo_azienda: [{ id: "Autonoma", nome: "Autonoma" }, { id: "Collegata", nome: "Collegata" }, { id: "Associata", nome: "Associata" }],

        tipi_finanziamento: [{ id: "Ordinaria", nome: "Ordinaria" }, { id: "4.0", nome: "4.0" }],

        finanziamenti: [{ id: "Finanziamento", nome: "Finanziamento" }, { id: "Leasing", nome: "Leasing" }],


        intestatari_mandato: [{ id: "Agila", nome: "Agila" }, { id: "Api", nome: "Api" }, { id: "Flora Sijolie Pagano", nome: "Flora Sijolie Pagano" }, { id: "Vico", nome: "Vico" }],


        readOnlyNomePratica: false,

        showNomePratica: false,

        removeValue: "S",

        sheetReferenti: false,
        searchRef: "",

        sheetGestori: false,

        referenti: [],
        referentiTemp: [],

        gestori: [],
        searchGest: "",

        valorePosizione: null,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        pratica: { tipo_progetto_id: "", nome: "", anno: "", companyid: "", login: "", investimento: "", minimo_mandato: "", intestatario_mandato: "", nickname_doxweb: "", data_mandato: "" },

        dipendenti: [],

        dipartimentiTemp: [],

        scopesTemp: [],

        ore: [],

        tipo_progetto: [],

        pratiche: [],

        praticheTemp: [],

        gruppi: [],

        tipo_controllo: [],

        //stati: [{ id: "N", name: "No" }, { id: "Y", name: "Si" }, { id: "1", name: "NA" }, { id: "2", name: "WIP" }],

        currAzienda: "",
        titolo: "Aggiungi Pratica",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],

    }),

    components: {
        VueAutonumeric,
    },


    methods: {


        filterTipoControllo: function (idtipoc) {

            window.$cookies.set("id_pratica_edit", this.$route.params.id, "9y");


            var idtp = btoa(this.pratica.tipo_progetto_id) + "l";
            var idtc = btoa(idtipoc) + "l";
            var idazi = btoa(this.pratica.companyid) + "l";


            // console.log("ID PRAT: ", this.$route.params.id);
            // console.log("ID TIPO PROG: ", btoa(this.pratica.tipo_progetto_id));
            // console.log("ID TIPO CTRL: ", btoa(idtipoc));
            // console.log("ID TIPO AZI: ", btoa(this.pratica.companyid));


            router.push({
                path: "/controlli/MA==l/" + idtp + "/" + idtc + "/" + idazi
            });


        },




        btnCancelContr: function () {

            this.$modal.show('popupContr');

        },

        btnConfirmContr: function () {

            this.pratica.contributo = this.valoreContributo;


            this.$modal.hide('popupContr');


        },

        btnCancelInv: function () {

            this.$modal.show('popupInv');

        },

        btnConfirmInv: function () {

            this.pratica.investimento = this.valoreInvestimento;


            this.$modal.hide('popupInv');


            this.setupButtons();


        },





        btnCancelMinMand: function () {

            this.$modal.show('popupMinMand');

        },

        btnConfirmMinMand: function () {

            this.pratica.minimo_mandato = this.valoreMinimoMandato;


            this.$modal.hide('popupMinMand');

            this.setupButtons();


        },

        btnCancelPrevisionale: function () {

            this.$modal.show('popupPrevisionale');

        },

        btnConfirmPrevisionale: function () {

            this.pratica.previsionale = this.valorePrevisionale;


            this.$modal.hide('popupPrevisionale');


        },


        setTipoControllo: function (id_tipo_progetto) {


            console.log("TIPO PROJ 2: ", id_tipo_progetto);

            console.log("PRAT TMP: ", this.tipoControlloTemp);

            var TipoControllo = [];

            if (id_tipo_progetto != "") {

                for (var i = 0; i < this.tipoControlloTemp.length; i++) {


                    if (parseInt(this.tipoControlloTemp[i].tipo_progetto_id) == parseInt(id_tipo_progetto)) {

                        TipoControllo.push({
                            id: this.tipoControlloTemp[i].id,
                            descrizione: this.tipoControlloTemp[i].descrizione
                        });


                    }


                }

            }

            console.log("PRAT SEL: ", TipoControllo);

            this.tipoControllo = TipoControllo;

        },

        chkCancelRemoveValue: function () {

            this.removeValue = "N";

        },


        chkRemoveValue: function () {

            if (this.removeValue == "S") {

                this.controllo.send_as_mail = "N";
                this.controllo.send_as_sms = "N";
                this.controllo.send_as_wapp = "N";

            }

        },

        btnConfirmPosizione: function () {

            this.controllo.posizione = this.valorePosizione;

            this.$modal.hide('popupPosizione');

            //this.setupButtons();

        },


        btnCancelPosizione: function () {

            this.$modal.hide('popupPosizione');

        },

        decodeString: function (value) {

            var v = value.substring(0, value.length - 1);

            console.log("VAL: ", v);

            return atob(v);

        },



        enableSaveButton: function () {

            var result = 1;

            // result = 0;
            console.log("PRATICA: ", this.pratica);

            if (this.pratica.tipo_progetto_id == "") {
                result = 0;
            }

            if (result == 1) {
                if (this.pratica.companyid == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.pratica.login == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.pratica.nome == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.pratica.investimento == "") {
                    result = 0;
                }
            }


            if (result == 1) {
                if (this.pratica.minimo_mandato == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.pratica.intestatario_mandato == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.pratica.nickname_doxweb == "") {
                    result = 0;
                }
            }


            if (result == 1) {
                if (this.pratica.data_mandato != "") {

                    if (this.pratica.data_mandato != null) {

                        var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;


                        if (!this.pratica.data_mandato.match(reg)) {

                            result = 0;

                        }


                    }



                }
            }



            return result;

        },

        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },

        setReferenti: function (id_azienda) {

            console.log("AZI SEL: ", id_azienda);

            console.log("REFS TMP: ", this.referentiTemp);

            var Referenti = [];

            if (id_azienda != "") {

                Referenti.push({
                    id: "",
                    referente: "Tutti"
                });

                for (var i = 0; i < this.referentiTemp.length; i++) {

                    if (parseInt(this.referentiTemp[i].companyid) == parseInt(id_azienda)) {

                        Referenti.push({
                            id: this.referentiTemp[i].id,
                            referente: this.referentiTemp[i].referente
                        });

                    }

                }


            }


            console.log("REFS SEL 2: ", Referenti);

            this.referenti = Referenti;

        },


        setPratiche: function (id_azienda, id_tipo_progetto) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("TIPO PROJ 2: ", id_tipo_progetto);

            console.log("PRAT TMP: ", this.praticheTemp);

            var Pratiche = [];

            if ((id_azienda != "") && (id_tipo_progetto != "")) {

                for (var i = 0; i < this.praticheTemp.length; i++) {

                    if ((parseInt(this.praticheTemp[i].companyid) == parseInt(id_azienda)) && (parseInt(this.praticheTemp[i].tipo_progetto_id) == parseInt(id_tipo_progetto))) {

                        Pratiche.push({
                            id: this.praticheTemp[i].id,
                            nome: this.praticheTemp[i].nome
                        });

                    }

                }

            }

            console.log("PRAT SEL: ", Pratiche);

            this.pratiche = Pratiche;

        },






        setScopes: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("SCOPES TMP: ", this.scopesTemp);

            var scopesAzienda = [];

            for (var i = 0; i < this.scopesTemp.length; i++) {

                if (parseInt(this.scopesTemp[i].company_id) == parseInt(id_azienda)) {

                    scopesAzienda.push({
                        id: this.scopesTemp[i].id,
                        name: this.scopesTemp[i].name
                    });

                }

            }

            console.log("SCOPES SEL: ", scopesAzienda);

            this.scopes = scopesAzienda;

        },


        manageClickGestori: async function (gestore) {

            console.log("REF SEL: ", gestore);


            this.sheetGestori = false;

            this.pratica.login = gestore.login;

            this.setupButtons();




        },





        manageClickReferenti: async function (referente) {

            console.log("REF SEL: ", referente);

            this.sheetReferenti = false;

            this.pratica.referenteid = referente.id;


            //this.setupButtons();
        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            var that = this;

            this.sheetAziende = false;

            this.pratica.companyid = azienda.companyid;

            this.setReferenti(azienda.companyid);



            if (this.pratica.tipo_progetto_id != "") {

                if (this.pratica.tipo_progetto_id != "1") {

                    setTimeout(() => {

                        that.updateNumTotPratiche();

                    }, 200);



                }


            }

            setTimeout(() => {

                that.getInfoCompany();

            }, 200);



            this.setupButtons();



        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                // var enableSave = this.enableSaveButton();

                // if (enableSave == 1) {

                //     pulsantis.push({
                //         text: "Salva",
                //         icon: "mdi-content-save",
                //         link: "/timesheet",
                //         id: "btn_save",
                //         disabled: false,
                //         image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                //         title: "Salva",
                //         width: 30
                //     });

                // }


                // if (this.cur_id > 0) {


                //     pulsantis.push({
                //         text: "Salva",
                //         icon: "mdi-content-save",
                //         link: "/timesheet",
                //         id: "btn_referenti",
                //         disabled: false,
                //         image: "https://app.emtool.eu/public/_lib/img/referenti_emt.png",
                //         title: "Referenti",
                //         width: 30
                //     });


                //     pulsantis.push({
                //         text: "Salva",
                //         icon: "mdi-content-save",
                //         link: "/timesheet",
                //         id: "btn_history_ctrls",
                //         disabled: false,
                //         image: "https://app.emtool.eu/public/_lib/img/storico-emt.png",
                //         title: "Storico Controlli",
                //         width: 30
                //     });


                //     pulsantis.push({
                //         text: "Salva",
                //         icon: "mdi-content-save",
                //         link: "/timesheet",
                //         id: "btn_filt_tipo_ctr",
                //         disabled: false,
                //         image: "https://app.emtool.eu/public/_lib/img/filter-edit-pratica.png",
                //         title: "Filtra controlli per tipo controllo selezionato e tipo progetto pratica",
                //         width: 22
                //     });



                // }



                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });



                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);





        },

        validate() {

            var that = this;

            var errore = "";

            if ($("#companyid").val() == "") {
                errore = "Specificare l'azienda";
            }

            if (errore == "") {

                if ($("#name").val() == "") {
                    errore = "Specificare il nome";
                }

            }

            if (errore == "") {

                if ($("#departmentid").val() == "") {
                    errore = "Specificare il dipartimento";
                }

            }


            if ($("#group_scope_id").val() != "") {

                that.gruppo.group_scope_id = $("#group_scope_id").val();

            }


            if (errore == "") {


                that.saveData();

            }

            if (errore != "") {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        initInsertGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                this.aziende = res.data.aziende;

                this.dipartimentiTemp = res.data.dipartimenti;

                this.scopesTemp = res.data.scope;


                // imposto di default l'azienda del filtro
                var v_company = window.$cookies.get("sel_filter_azienda_gruppi");

                that.gruppo.companyid = v_company;

                this.setDipartimenti(v_company);

                this.setScopes(v_company);


                $("#itemCompany").addClass("item-has-value");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        initEditGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipratiche.editPratica(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editPratica", res);


                that.tipoControlloTemp = res.data.tipo_controllo;

                that.setTipoControllo(res.data.pratica.tipo_progetto_id);


                var nome_pratica = res.data.pratica.nome;

                console.log("NOME PRATICA: ", nome_pratica);


                that.setReferenti(res.data.pratica.companyid);

                that.pratica = res.data.pratica;

                that.statoFatturatoReadOnly = false;
                that.pratica.stato_fatturato = res.data.is_fatt_exist;
                that.statoFatturatoReadOnly = true;

                that.statoPagamentoReadOnly = false;
                that.pratica.stato_pagamento = res.data.is_fatt_pag;
                that.statoPagamentoReadOnly = true;


                if (res.data.pratica.referenteid != null) {

                    $("#itemReferente").addClass("item-has-value");

                }


                that.showDateInsUpd = true;

                var elements = res.data.pratica.created_at.toString().split(" ");

                var elements4 = elements[0].split("-");



                that.dataInserimento = elements4[2] + "/" + elements4[1] + "/" + elements4[0];


                var elements2 = res.data.pratica.updated_at.toString().split(" ");


                var elements5 = elements2[0].split("-");



                that.dataUltimaModifica = elements5[2] + "/" + elements5[1] + "/" + elements5[0];




                that.getInfoCompany();


                if (res.data.pratica.data_mandato != null) {

                    var elements3 = res.data.pratica.data_mandato.toString().split("-");

                    console.log("DATA MANDATO: ", elements3);

                    that.pratica.data_mandato = elements3[2] + "/" + elements3[1] + "/" + elements3[0];

                }






                that.setupButtons();

                if (res.data.pratica.tipo_progetto_id != 1) // diverso da sabatini
                {

                    that.showRow1 = true;
                    that.showRow2 = true;
                    that.showRow3 = false;
                    that.showRow4 = false;
                    that.showRow9 = false;
                    that.showRow10 = false;

                    if (res.data.pratica.tipo_progetto_id == 3) // ricerca e sviluppo
                    {


                        that.showRow11 = false;
                        that.showRow12 = true;


                    }


                    setTimeout(() => {

                        console.log("NOME PRATICA 2: ", nome_pratica);

                        var v_anno = nome_pratica.substr(nome_pratica.length - 4);

                        console.log("ANNO NOME PRATICA: ", v_anno);

                        //$("#anno").val(v_anno);

                        that.pratica.anno = v_anno;

                        that.setupButtons();



                    }, 300);

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.getPianoOrario(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianoOrario", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

                this.pianoorario = res.data.piano_orario;

                $("#itemCompany").addClass("item-has-value");

                if (this.pianoorario.lunedi != null) {

                    $("#itemLunedi").addClass("item-has-value");

                }

                if (this.pianoorario.martedi != null) {

                    $("#itemMartedi").addClass("item-has-value");

                }

                if (this.pianoorario.mercoledi != null) {

                    $("#itemMercoledi").addClass("item-has-value");

                }

                if (this.pianoorario.giovedi != null) {

                    $("#itemGiovedi").addClass("item-has-value");

                }

                if (this.pianoorario.venerdi != null) {

                    $("#itemVenerdi").addClass("item-has-value");

                }

                if (this.pianoorario.sabato != null) {

                    $("#itemSabato").addClass("item-has-value");

                }

                if (this.pianoorario.domenica != null) {

                    $("#itemDomenica").addClass("item-has-value");

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        updateNumTotPratiche: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipratiche.getTotPratiche(
                that.pratica.nome,
                that.pratica.anno,
                that.pratica.companyid,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTotPratiche", res);


                that.readOnlyNumeroTotPratiche = false;

                that.pratica.num_tot_pratiche = res.data.NumTotal;

                that.readOnlyNumeroTotPratiche = true;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },


        getInfoCompany: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipratiche.getInfoCompany(
                v_token,
                that.pratica.companyid
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoCompany", res);

                that.chiusEsercReadOnly = false;
                that.pratica.chiusura_esercizio = res.data.desc_chiusura_esercizio;
                that.chiusEsercReadOnly = true;

                that.dimAziReadOnly = false;
                that.pratica.dimensione_azienda = res.data.dimensione_azienda;
                that.dimAziReadOnly = true;

                that.dipAziReadOnly = false;
                that.pratica.dipendenti_azienda = res.data.n_dipendenti;
                that.dipAziReadOnly = true;


                that.nickDoxReadonly = false;
                that.pratica.nickname_doxweb = res.data.nickname_doxweb;

                if (that.pratica.nickname_doxweb != "") {
                    that.nickDoxReadonly = true;

                }


                that.setupButtons();




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },




        saveData: async function () {


            var v_token = window.$cookies.get("token");
            var that = this;

            console.log("PRATICA: ", that.pratica);
            console.log("CUR ID: ", that.cur_id);
            console.log("TOKEN: ", v_token);


            if (that.cur_id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apipratiche.insertPratica(
                    that.pratica,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertPratica", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/pratiche"
                            });

                        }, 200);


                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.Errore,
                            showConfirmButton: false,
                            timer: 8000
                        });

                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );


            }


            if (that.cur_id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apipratiche.updatePratica(
                    that.pratica,
                    v_token,
                    that.$route.params.id
                ).then((res2) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updatePratica", res2);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/pratiche"
                        });

                    }, 200);

                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err2);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response2);

                }

                );


            }





        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.noDevMobile {}

.devMobile {
    display: none;
}

.w-container-edit-pratica .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.w-container-edit-pratica {
    width: 1000px;
    padding: 0px;
    z-index: 3;
    overflow-x: hidden !important;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-edit-pratica {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .noDevMobile {
        display: none;
    }

    .devMobile {
        display: block;
    }

    .edit_pratica_agila .v-label {
        font-size: 1em !important
    }

    .edit_pratica_agila .v-input {
        font-size: 10px !important;
        padding: 10px !important;
    }

    .edit_pratica_agila ion-select {
        font-size: 10px !important;
    }


}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetReferenti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetGestori {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemReferente {
    margin-left: 15px;
}

.itemGestore {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}

.edit_pratica_agila .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_pratica_agila .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.edit_pratica_agila ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_pratica_agila .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.edit_pratica_agila .v-input.cl-checkbox.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.primary--text {
    margin-top: 0px !important;
}

.edit_pratica_agila .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox {
    margin-top: 0px !important;
}</style>
