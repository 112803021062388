<template>
    <v-container class="w-container v-overflow editParente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                    background-color: white;
                    color: black;
                    font-weight: bold;
                    padding: 4px;
                    font-size: 10px;
                    min-width:100vw;
                    border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text=""
                                :value="valoriSelezionati.companyid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid" :value='item.companyid'> {{
                                    item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <ion-item id="itemAnno"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Anno </ion-label>

                        <ion-select id="year" name="year" class='year' cancel-Text="Chiudi" done-Text=""
                            :value="valoriSelezionati.year" interface="action-sheet">

                            <ion-select-option v-for="item in anni" :key="item.value" :value='item.value'> {{
                                item.description
                            }}</ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <ion-item id="itemMese"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Mese </ion-label>

                        <ion-select id="month" name="month" class='month' cancel-Text="Chiudi" done-Text=""
                            :value="valoriSelezionati.month" interface="action-sheet">

                            <ion-select-option v-for="item in mesi" :key="item.value" :value='item.value'> {{
                                item.desc_month
                            }}</ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">
                    <v-checkbox label="Invia Ore Straordinario" v-model="valoriSelezionati.send_overtime" true-value="S"
                        false-value="N" id="chkMinutes" style="font-size:1em"></v-checkbox>
                </v-col>

            </v-row>

        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <modal name="popupWorkInProgress" :clickToClose="false" :width="350" :height="100">

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important">

                        <div id="divMessages" style="font-size:12px;font-weight:bold;text-align:center;margin-top:31px">
                            Prego Attendere</div>

                    </v-col>

                </v-row>

            </modal>
        </template>


        <template>
            <modal name="popupErrorValidation" :clickToClose="false" :width="379" :height="330">

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:140px">

                        <v-img alt="" src="@/assets/error.png" max-width="60" max-height="60" />

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;text-align:left">

                        <div style="font-size:12px;margin-left:10px" id="txtError2"></div>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12" style="font-weight:bold;text-align:left">

                        <div style="font-size:12px;width:100%;min-height:100px;max-height:100px;overflow-y:auto;margin-left:10px" id="txtError3"></div>

                    </v-col>

                </v-row>



                <v-row>

                    <v-col cols="12" md="12">

                        <div style="display:flex;justify-content:center">

                            <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                title="Conferma" @click="btnConfirmErrorValidation" />


                        </div>

                    </v-col>

                </v-row>



            </modal>
        </template>

        <template>
            <modal name="popupErrorValidation2" :clickToClose="false" :width="350" :height="186">

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:140px">

                        <v-img alt="" src="@/assets/error.png" max-width="60" max-height="60" />

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;text-align:center">

                        <div style="font-size:12px" id="txtError"></div>

                    </v-col>

                </v-row>


                <v-row>

                    <v-col cols="12" md="12">

                        <div style="display:flex;justify-content:center">

                            <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                title="Conferma" @click="btnConfirmErrorValidation2" />


                        </div>

                    </v-col>

                </v-row>



            </modal>
        </template>




    </v-container>
</template>

<script>
//import apiusers from "../utils/users/apiusers";
import apiinviopresenze from "../utils/inviopresenze/apiinviopresenze";

import {
    bus
} from "../main";

import router from ".././router";
import $ from 'jquery';

import Vue from "vue";
import VModal from 'vue-js-modal';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        Vue.use(VModal, {
            dialog: true
        });

        var pointerVue = this;

        this.setupButtons();

        setTimeout(() => {

            this.syncFilterData();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });

            $(".year").on("ionChange", function (opt) {
                console.log(opt);

                if (($("#companyid").val() != "") && ($("#year").val() != "") && ($("#month").val() != "")) {

                    pointerVue.hideShowButtons(true);

                }

            });

            $(".month").on("ionChange", function (opt) {
                console.log(opt);

                if (($("#companyid").val() != "") && ($("#year").val() != "") && ($("#month").val() != "")) {

                    pointerVue.hideShowButtons(true);

                }

            });

        });

        pointerVue.hideShowButtons(false);

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({
        titolo: "Invio Presenze",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],

        anni: [],
        mesi: [],

        currAzienda: "",

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        valoriSelezionati: {
            send_overtime: "N"
        },

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {

        btnConfirmErrorValidation: function()
        {

            this.$modal.hide('popupErrorValidation');

        },

        btnConfirmErrorValidation2: function()
        {

            this.$modal.hide('popupErrorValidation2');

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.valoriSelezionati.companyid = azienda.companyid;

            // this.hideShowButtons(true);

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-checkbox-marked-circle-outline",
                        image: "https://app.emtool.eu/public/_lib/img/conferma-prjweb.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        title: "Conferma",
                        width: 30
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/conferma-prjweb.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            this.sendDocuments();

            // console.log("AZIENDA: ", that.selAzienda);

            // window.$cookies.set("sel_filter_azienda_utenti", that.selAzienda, "9y");

            // router.push({
            //     path: "/users"
            // });

        },

        syncFilterData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinviopresenze.getFilterData(
                v_token,
                "emt"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getFilterData", res);

                try {


                    this.aziende = res.data.company;
                    this.anni = res.data.year;

                    this.mesi = res.data.month;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        sendDocuments: async function () {

            console.log("ok");

            this.$modal.show('popupWorkInProgress');

            var that = this;

            var v_token = window.$cookies.get("token");


            var response = await apiinviopresenze.stepValidate(
                $("#companyid").val(),
                $("#year").val(),
                $("#month").val(),
                that.valoriSelezionati.send_overtime,
                v_token
            ).then(async (res) => {

                console.log("res from stepValidate", res);


                if (res.data.Result == "OK")
                {

                    var response2 = await apiinviopresenze.stepInit(
                        v_token
                    ).then(async (res2) => {

                        console.log("res from stepInit", res2);

                        if (res2.data.Result == "OK") {

                            console.log("RESPONSE2: ", response2);

                            $("#divMessages").html("Creazione LUL Prego Attendere");

                            var response3 = await apiinviopresenze.stepLUL(
                                $("#year").val(),
                                $("#month").val(),
                                $("#companyid").val(),
                                that.valoriSelezionati.send_overtime,
                                v_token
                            ).then(async (res3) => {

                                console.log("res from stepLUL", res3);

                                if (res3.data.Result == "OK") {

                                    console.log("RESPONSE3: ", response3);

                                    $("#divMessages").html("Creazione PDF Prego Attendere");

                                    var response4 = await apiinviopresenze.stepPdf(
                                        $("#year").val(),
                                        $("#month").val(),
                                        $("#companyid").val(),
                                        that.valoriSelezionati.send_overtime,
                                        v_token
                                    ).then(async (res4) => {

                                        console.log("res from stepPdf", res4);

                                        if (res4.data.Result == "OK") {

                                            console.log("RESPONSE4: ", response4);

                                            $("#divMessages").html("Creazione Rile01 Prego Attendere");

                                            var response5 = await apiinviopresenze.stepRile01(
                                                $("#year").val(),
                                                $("#month").val(),
                                                $("#companyid").val(),
                                                that.valoriSelezionati.send_overtime,
                                                v_token
                                            ).then(async (res5) => {

                                                console.log("res from stepRile01", res5);

                                                if (res5.data.Result == "OK") {

                                                    console.log("RESPONSE5: ", response5);

                                                    $("#divMessages").html("Creazione Annotazioni Prego Attendere");

                                                    var response6 = await apiinviopresenze.stepAnn(
                                                        $("#year").val(),
                                                        $("#month").val(),
                                                        $("#companyid").val(),
                                                        that.valoriSelezionati.send_overtime,
                                                        v_token
                                                    ).then(async (res6) => {

                                                        console.log("res from stepAnn", res6);
                                                        console.log("res from stepAnn", response6);

                                                        if (res6.data.Result == "OK") {

                                                            console.log("RESPONSE6: ", response6);


                                                            $("#divMessages").html("Creazione Rile02 Prego Attendere");

                                                            var response7 = await apiinviopresenze.stepRile02(
                                                                $("#year").val(),
                                                                $("#month").val(),
                                                                $("#companyid").val(),
                                                                that.valoriSelezionati.send_overtime,
                                                                v_token
                                                            ).then(async (res7) => {

                                                                console.log("res from stepRile02", res7);
                                                                console.log("res from stepRile02", response7);


                                                                $("#divMessages").html("Invio Mail Prego Attendere");

                                                                var response8 = await apiinviopresenze.stepMail(
                                                                    $("#year").val(),
                                                                    $("#month").val(),
                                                                    $("#companyid").val(),
                                                                    that.valoriSelezionati.send_overtime,
                                                                    v_token
                                                                ).then(async (res8) => {

                                                                    console.log("res from stepMail", res8);
                                                                    console.log("res from stepMail", response8);

                                                                    if (res8.data.Result == "OK") {

                                                                        console.log("RESPONSE7: ", response8);

                                                                        that.$modal.hide('popupWorkInProgress');

                                                                        that.$swal({
                                                                            icon: "success",
                                                                            text: "Processo completato, una mail è inviata all'ufficio paghe ed un'altra al proprio indirizzo di posta",
                                                                            showConfirmButton: false,
                                                                            timer: 2000
                                                                        });

                                                                    } else {

                                                                        that.$modal.hide('popupWorkInProgress');

                                                                        that.$swal({
                                                                            icon: "error",
                                                                            text: res8.data.Errore,
                                                                            showConfirmButton: false,
                                                                            timer: 2000
                                                                        });

                                                                    }

                                                                }).catch(err6 => {


                                                                    console.log("err from stepMail", err6);


                                                                    that.$root.$children[0].showProgress = false;
                                                                    console.log(err6);
                                                                    var msg = err6.response.data.Error;

                                                                    that.$swal({
                                                                        icon: "error",
                                                                        text: msg,
                                                                        showConfirmButton: false,
                                                                        timer: 8000
                                                                    });
                                                                    console.log("Errori", "Non è stato possibile caricare i dati");
                                                                    console.log("response", response);

                                                                }

                                                                );



                                                               

                                                            }).catch(err6 => {


                                                                console.log("err from stepRile02", err6);


                                                                that.$root.$children[0].showProgress = false;
                                                                console.log(err6);
                                                                var msg = err6.response.data.Error;

                                                                that.$swal({
                                                                    icon: "error",
                                                                    text: msg,
                                                                    showConfirmButton: false,
                                                                    timer: 8000
                                                                });
                                                                console.log("Errori", "Non è stato possibile caricare i dati");
                                                                console.log("response", response);

                                                            }

                                                            );

                                                        } else {

                                                            that.$modal.hide('popupWorkInProgress');

                                                            that.$swal({
                                                                icon: "error",
                                                                text: res3.data.Errore,
                                                                showConfirmButton: false,
                                                                timer: 2000
                                                            });

                                                        }

                                                    }).catch(err6 => {
                                                        //that.$root.$children[0].showProgress = false;
                                                        console.log(err6);
                                                        var msg = err6.response.data.Error;

                                                        that.$swal({
                                                            icon: "error",
                                                            text: msg,
                                                            showConfirmButton: false,
                                                            timer: 8000
                                                        });
                                                        console.log("Errori", "Non è stato possibile caricare i dati");
                                                        console.log("response", response);

                                                    }

                                                    );

                                                } else {

                                                    that.$modal.hide('popupWorkInProgress');

                                                    that.$swal({
                                                        icon: "error",
                                                        text: res3.data.Errore,
                                                        showConfirmButton: false,
                                                        timer: 2000
                                                    });

                                                }

                                            }).catch(err5 => {
                                                //that.$root.$children[0].showProgress = false;
                                                console.log(err5);
                                                var msg = err5.response.data.Error;

                                                that.$swal({
                                                    icon: "error",
                                                    text: msg,
                                                    showConfirmButton: false,
                                                    timer: 8000
                                                });
                                                console.log("Errori", "Non è stato possibile caricare i dati");
                                                console.log("response", response);

                                            }

                                            );

                                        } else {

                                            that.$modal.hide('popupWorkInProgress');

                                            that.$swal({
                                                icon: "error",
                                                text: res3.data.Errore,
                                                showConfirmButton: false,
                                                timer: 2000
                                            });

                                        }

                                    }).catch(err4 => {
                                        //that.$root.$children[0].showProgress = false;
                                        console.log(err4);
                                        var msg = err4.response.data.Error;

                                        that.$swal({
                                            icon: "error",
                                            text: msg,
                                            showConfirmButton: false,
                                            timer: 8000
                                        });
                                        console.log("Errori", "Non è stato possibile caricare i dati");
                                        console.log("response", response);

                                    }

                                    );

                                } else {

                                    that.$modal.hide('popupWorkInProgress');

                                    that.$swal({
                                        icon: "error",
                                        text: res3.data.Errore,
                                        showConfirmButton: false,
                                        timer: 2000
                                    });

                                }

                            }).catch(err3 => {
                                //that.$root.$children[0].showProgress = false;
                                console.log(err3);
                                var msg = err3.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("Errori", "Non è stato possibile caricare i dati");
                                console.log("response", response);

                            }

                            );

                        } else {

                            that.$modal.hide('popupWorkInProgress');

                            that.$swal({
                                icon: "error",
                                text: res2.data.Errore,
                                showConfirmButton: false,
                                timer: 2000
                            });

                        }

                    }).catch(err2 => {
                        //that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg = err2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile caricare i dati");
                        console.log("response", response);

                    }

                    );




                }
                else
                {

                    if (res.data.DettagliErrore != "")
                    {

                        this.$modal.hide('popupWorkInProgress');

                        this.$modal.show('popupErrorValidation');

                        setTimeout(() => {
                            
                            $("#txtError2").html(res.data.TitoloErrore);

                            $("#txtError3").html(res.data.DettagliErrore);

                        }, 200);

                    }
                    else
                    {

                        this.$modal.hide('popupWorkInProgress');

                        this.$modal.show('popupErrorValidation2');

                        setTimeout(() => {
                            
                            $("#txtError").html(res.data.TitoloErrore);


                        }, 200);



                    }



                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editParente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editParente .v-input {
    font-size: 1.2em;
}

.editParente .v-select {
    font-size: 1.2em;
}

.editParente .v-label {
    font-size: 1em;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
