import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

function getUrlDomainGlobal(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


}

var main_domain = getUrlDomainGlobal("");

class APIDoxweb {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;

    }



    getUrlApiDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + "api/newdoxweb/" + nome_metodo;

    }


    async getInfoUser(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlApiDomain("getInfoUser"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getInfoUserCedolini(user, v_company_id, v_year, v_month) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_company_id", v_company_id);
        formData.append("v_year", v_year);
        formData.append("v_month", v_month);

        return axios.post(this.getUrlApiDomain("getInfoUserCedolini"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async downloadFile(path_file) {

        let formData = new FormData();

        formData.append("path_file", path_file);

        return axios.post(this.getUrlDomain("downloadFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async createFolder(login, cat, parPath, folderContainer) {

        let formData = new FormData();

        formData.append("login", login);
        formData.append("cat", cat);
        formData.append("parPath", parPath);
        formData.append("folderContainer", folderContainer);

        return axios.post(this.getUrlApiDomain("addCategoryDox"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async renameFolderFile(action, uname, key, v_new, v_old, a, b, app) {

        let formData = new FormData();

        formData.append("action", action);
        formData.append("uname", uname);
        formData.append("key", key);
        formData.append("new", v_new);
        formData.append("old", v_old);
        formData.append("a", a);
        formData.append("b", b);
        formData.append("app", app);

        return axios.post(this.getUrlApiDomain("renameFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async checkFolderFileExist(current_path, old_name, new_name, is_file) {

        let formData = new FormData();

        formData.append("current_path", current_path);
        formData.append("new_name", new_name);
        formData.append("is_file", is_file);
        formData.append("old_name", old_name);

        return axios.post(this.getUrlApiDomain("checkFolderFileExist"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async renameFile(action, uname, key, v_new, v_old) {

        let formData = new FormData();

        formData.append("action", action);
        formData.append("uname", uname);
        formData.append("key", key);
        formData.append("new", v_new);
        formData.append("old", v_old);

        return axios.post(this.getUrlApiDomain("renameFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getInfoUploadFile(user) {

        let formData = new FormData();

        formData.append("user", user);

        return axios.post(this.getUrlApiDomain("getInfoUploadFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async uploadFile(key, uploadcedolini, choice, file) {

        let formData = new FormData();

        formData.append("key", key);
        formData.append("uploadcedolini", uploadcedolini);
        formData.append("choice", choice);
        formData.append("file", file);

        return axios.post(this.getUrlApiDomain("uploadf"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async uploadFileNew(key, uploadcedolini, choice, file) {

        let formData = new FormData();

        formData.append("key", key);
        formData.append("uploadcedolini", uploadcedolini);
        formData.append("choice", choice);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("caricaPdfCedolini"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFolder(app, folder_file, action, id, login, a, b, folderContain) {

        let formData = new FormData();

        formData.append("app", app);
        formData.append("folder_file", folder_file);
        formData.append("action", action);
        formData.append("id", id);
        formData.append("login", login);
        formData.append("a", a);
        formData.append("b", b);
        formData.append("folderContain", folderContain);

        return axios.post(this.getUrlApiDomain("removeFolderDox"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFile(action, key, keys, folderContain) {

        let formData = new FormData();

        formData.append("action", action);
        formData.append("key", key);
        formData.append("keys", keys);
        formData.append("folderContain", folderContain);

        return axios.post(this.getUrlApiDomain("removeFileNew"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async syncroUpload(path_file, nome_file) {

        let formData = new FormData();

        formData.append("path_file", path_file);
        formData.append("nome_file", nome_file);

        return axios.post(this.getUrlApiDomain("syncroUpload"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async loadPermissions(dest, group_id, login, node, lastSelection) {

        let formData = new FormData();

        formData.append("dest", dest);
        formData.append("group_id", group_id);
        formData.append("login", login);
        formData.append("node", node);
        formData.append("lastSelection", lastSelection);

        return axios.post(this.getUrlApiDomain("reactInfoShare"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async loadPermissionsSU(dest) {

        let formData = new FormData();

        formData.append("dest", dest);

        return axios.post(this.getUrlApiDomain("reactInfoShareSU"), formData, { 'Content-Type': 'multipart/form-data' });

    }





    async getGroups(uname) {

        let formData = new FormData();

        formData.append("uname", uname);

        return axios.post(this.getUrlApiDomain("getGroups"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updatePermissions(dest, group_id, login, node, lastSelection, field, val) {

        let formData = new FormData();

        formData.append("dest", dest);
        formData.append("group_id", group_id);
        formData.append("login", login);
        formData.append("node", node);
        formData.append("lastSelection", lastSelection);
        formData.append("field", field);
        formData.append("val", val);

        return axios.post(this.getUrlApiDomain("reactUpdateShare"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async notifyByFolder(object, folder_id, folder_text, login, useremail) {

        let formData = new FormData();

        formData.append("object", object);
        formData.append("folder_id", folder_id);
        formData.append("folder_text", folder_text);
        formData.append("login", login);
        formData.append("useremail", useremail);

        return axios.post(this.getUrlApiDomain("notifyByFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async notifyByFolderNew(object, folder_id, folder_text, login, useremail, document_name) {

        let formData = new FormData();

        formData.append("object", object);
        formData.append("folder_id", folder_id);
        formData.append("folder_text", folder_text);
        formData.append("login", login);
        formData.append("useremail", useremail);
        formData.append("document_name", document_name);

        return axios.post(this.getUrlDomain("notifyByFolderNew"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async checkFileExist(folder, file_name) {

        let formData = new FormData();

        formData.append("folder", folder);
        formData.append("file_name", file_name);

        return axios.post(this.getUrlApiDomain("checkFileExist"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async checkFileMultiExist(folder, file_name) {

        let formData = new FormData();

        formData.append("folder", folder);
        formData.append("file_name", file_name);

        return axios.post(this.getUrlApiDomain("checkFileMultiExist"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async updatePermissionsCreateFolderNotAdmin(dest, group_id, login, node, lastSelection) {

        let formData = new FormData();

        formData.append("dest", dest);
        formData.append("group_id", group_id);
        formData.append("login", login);
        formData.append("node", node);
        formData.append("lastSelection", lastSelection);

        return axios.post(this.getUrlApiDomain("reactUpdateShareCreateFolderNotAdmin"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async savePermissions(dest, group_id, login, node, lastSelection, field, val, v_p_enter,
        v_p_show,
        v_p_remove_file,
        v_p_rename_file_folder,
        v_p_remove_folder,
        v_p_set_password,
        v_p_foldersignature,
        v_p_upload,
        v_p_worker,
        v_p_email,
        v_p_copy,
        v_p_otp_delete_file,
        v_p_otp_delete_folder,
        v_p_create_folder,
        v_p_edit_permissions,
        v_p_download_and_delete_file,
        v_p_zip_folder, 
        v_p_send_file_by_mail, 
        v_p_expired_file) {


        let formData = new FormData();

        formData.append("dest", dest);
        formData.append("group_id", group_id);
        formData.append("login", login);
        formData.append("node", node);
        formData.append("lastSelection", lastSelection);
        formData.append("field", field);
        formData.append("val", val);
        formData.append("v_p_enter", v_p_enter);
        formData.append("v_p_show", v_p_show);
        formData.append("v_p_remove_file", v_p_remove_file);
        formData.append("v_p_rename_file_folder", v_p_rename_file_folder);
        formData.append("v_p_remove_folder", v_p_remove_folder);
        formData.append("v_p_set_password", v_p_set_password);
        formData.append("v_p_foldersignature", v_p_foldersignature);
        formData.append("v_p_upload", v_p_upload);
        formData.append("v_p_worker", v_p_worker);
        formData.append("v_p_email", v_p_email);
        formData.append("v_p_copy", v_p_copy);
        formData.append("v_p_otp_delete_file", v_p_otp_delete_file);
        formData.append("v_p_otp_delete_folder", v_p_otp_delete_folder);
        formData.append("v_p_create_folder", v_p_create_folder);
        formData.append("v_p_edit_permissions", v_p_edit_permissions);
        formData.append("v_p_download_and_delete_file", v_p_download_and_delete_file);
        formData.append("v_p_zip_folder", v_p_zip_folder);
        formData.append("v_p_send_file_by_mail", v_p_send_file_by_mail);
        formData.append("v_p_expired_file", v_p_expired_file);

        return axios.post(this.getUrlDomain("savePermissions"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async savePermissionsSU(dest, field, val, v_p_enter,
        v_p_show,
        v_p_remove_file,
        v_p_rename_file_folder,
        v_p_remove_folder,
        v_p_set_password,
        v_p_foldersignature,
        v_p_upload,
        v_p_worker,
        v_p_email,
        v_p_copy,
        v_p_otp_delete_file,
        v_p_otp_delete_folder,
        v_p_create_folder,
        v_p_edit_permissions,
        v_p_download_and_delete_file,
        v_p_zip_folder, 
        v_p_send_file_by_mail, 
        v_p_expired_file) {


        let formData = new FormData();

        formData.append("dest", dest);
        formData.append("field", field);
        formData.append("val", val);
        formData.append("v_p_enter", v_p_enter);
        formData.append("v_p_show", v_p_show);
        formData.append("v_p_remove_file", v_p_remove_file);
        formData.append("v_p_rename_file_folder", v_p_rename_file_folder);
        formData.append("v_p_remove_folder", v_p_remove_folder);
        formData.append("v_p_set_password", v_p_set_password);
        formData.append("v_p_foldersignature", v_p_foldersignature);
        formData.append("v_p_upload", v_p_upload);
        formData.append("v_p_worker", v_p_worker);
        formData.append("v_p_email", v_p_email);
        formData.append("v_p_copy", v_p_copy);
        formData.append("v_p_otp_delete_file", v_p_otp_delete_file);
        formData.append("v_p_otp_delete_folder", v_p_otp_delete_folder);
        formData.append("v_p_create_folder", v_p_create_folder);
        formData.append("v_p_edit_permissions", v_p_edit_permissions);
        formData.append("v_p_download_and_delete_file", v_p_download_and_delete_file);
        formData.append("v_p_zip_folder", v_p_zip_folder);
        formData.append("v_p_send_file_by_mail", v_p_send_file_by_mail);
        formData.append("v_p_expired_file", v_p_expired_file);

        return axios.post(this.getUrlDomain("savePermissionsSU"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async copyPermissions(id_folder_orig, name_folder_orig, level_folder_origin, id_folder_dest, name_folder_dest, level_folder_dest, flag_recursive) {

        let formData = new FormData();

        formData.append("id_folder_orig", id_folder_orig);
        formData.append("name_folder_orig", name_folder_orig);
        formData.append("level_folder_origin", level_folder_origin);

        formData.append("id_folder_dest", id_folder_dest);
        formData.append("name_folder_dest", name_folder_dest);
        formData.append("level_folder_dest", level_folder_dest);

        formData.append("flag_recursive", flag_recursive);

        return axios.post(this.getUrlDomain("copyPermissions"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async checkSignature(username, data) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("data", data);

        return axios.post(this.getUrlDomain("checkSignature"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async sendSignature(username, data, signature) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("data", data);
        formData.append("signature", signature);

        return axios.post(this.getUrlDomain("sendSignature"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async sendMailCedolini(object, folder_id, document_name, mdate, login, useremail) {

        let formData = new FormData();

        formData.append("object", object);
        formData.append("folder_id", folder_id);
        formData.append("document_name", document_name);
        formData.append("mdate", mdate);
        formData.append("login", login);
        formData.append("useremail", useremail);

        return axios.post(this.getUrlApiDomain("notifyByFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    
    async sendMailUtenteCedolini(action, key, codice_fiscale, v_anno, v_mese, v_id_tipo_folder) {

        let formData = new FormData();

        formData.append("action", action);
        formData.append("key", key);
        formData.append("codice_fiscale", codice_fiscale);
        formData.append("v_anno", v_anno);
        formData.append("v_mese", v_mese);
        formData.append("v_id_tipo_folder", v_id_tipo_folder);

        return axios.post(this.getUrlDomain("public/serviceNew.php"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async addFavorite(username, folder_id, folder_name, v_folder_attrib, v_info_folder_parent, v_id_folder_nav, v_name_folders_nav, v_folder_level, v_full_path_folder_nav) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("folder_id", folder_id);
        formData.append("folder_name", folder_name);
        formData.append("v_folder_attrib", v_folder_attrib);
        formData.append("v_info_folder_parent", v_info_folder_parent);
        formData.append("v_id_folder_nav", v_id_folder_nav);
        formData.append("v_name_folders_nav", v_name_folders_nav);
        formData.append("v_folder_level", v_folder_level);
        formData.append("v_full_path_folder_nav", v_full_path_folder_nav);

        return axios.post(this.getUrlDomain("addFavorite"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getFavorites(username) {

        let formData = new FormData();

        formData.append("username", username);

        return axios.post(this.getUrlDomain("getFavorites"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFavorites(username, ids) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("ids", ids);

        return axios.post(this.getUrlDomain("deleteFavorites"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async goToFavorite(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("goToFavorite"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getPswFolder(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("getPswFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async setPasswordFolder(username, v_id, v_password) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("v_id", v_id);
        formData.append("v_password", v_password);

        return axios.post(this.getUrlDomain("setPasswordFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async checkPasswordFolderEnabled(v_id, username) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("username", username);

        return axios.post(this.getUrlDomain("checkPasswordFolderEnabled"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async validatePasswordFolder(username, v_id, v_password) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("v_id", v_id);
        formData.append("v_password", v_password);

        return axios.post(this.getUrlDomain("validatePasswordFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async sendMailRecPassword(username, v_id, v_mail) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("v_id", v_id);
        formData.append("v_mail", v_mail);

        return axios.post(this.getUrlDomain("sendMailRecPassword"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async changePswFolder(v_id, v_password) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("v_password", v_password);

        return axios.post(this.getUrlDomain("changePswFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initInsertUserGroupPswFolder(username) {

        let formData = new FormData();

        formData.append("username", username);

        return axios.post(this.getUrlDomain("initInsertUserGroupPswFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEditUserGroupPswFolder(username, v_id) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("initEditUserGroupPswFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertUserGroupPswFolder(folder_pswd) {

        let formData = new FormData();

        for (var key in folder_pswd) {
            formData.append(key, folder_pswd[key]);
        }

        return axios.post(this.getUrlDomain("insertUserGroupPswFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateUserGroupPswFolder(folder_pswd, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        for (var key in folder_pswd) {
            formData.append(key, folder_pswd[key]);
        }

        return axios.post(this.getUrlDomain("updateUserGroupPswFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async deleteUserGroupPswFolder(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("deleteUserGroupPswFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async saveConfigServer(config_server) {

        let formData = new FormData();

        for (var key in config_server) {
            formData.append(key, config_server[key]);
        }

        return axios.post(this.getUrlDomain("saveConfigServer"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async editConfigServer(username) {

        let formData = new FormData();

        formData.append("username", username);

        return axios.post(this.getUrlDomain("editConfigServer"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getContentFileForView(key) {

        let formData = new FormData();

        formData.append("key", key);

        return axios.post(this.getUrlDomain("getContentFileForView"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async sendMailCedoliniAlert(ids, year, month, v_companyid) {

        let formData = new FormData();

        formData.append("ids", ids);
        formData.append("year", year);
        formData.append("month", month);
        formData.append("v_companyid", v_companyid);

        return axios.post(this.getUrlDomain("sendMailCedoliniAlert"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteElementShared(id_element, id_folder, type_element) {

        let formData = new FormData();

        formData.append("id_element", id_element);
        formData.append("id_folder", id_folder);
        formData.append("type_element", type_element);

        return axios.post(this.getUrlDomain("deleteElementShared"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteElementSharedSU(id_element) {

        let formData = new FormData();

        formData.append("id_element", id_element);

        return axios.post(this.getUrlDomain("deleteElementSharedSU"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async setStateZipFolder(folder_id, name_element, folder_file) {

        let formData = new FormData();

        formData.append("folder_id", folder_id);
        formData.append("name_element", name_element);
        formData.append("folder_file", folder_file);

        return axios.post(this.getUrlDomain("setStateZipFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async zipFolder(folder_id, name_element, folder_file) {

        let formData = new FormData();

        formData.append("folder_id", folder_id);
        formData.append("name_element", name_element);
        formData.append("folder_file", folder_file);

        return axios.post(this.getUrlDomain("zipFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteZipFolder(name_element) {

        let formData = new FormData();

        formData.append("name_element", name_element);

        return axios.post(this.getUrlDomain("deleteZipFolder"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async uploadDocumentsMulti(key, uploadcedolini, choice, files) {

        let formData = new FormData();

        formData.append("key", key);
        formData.append("uploadcedolini", uploadcedolini);
        formData.append("choice", choice);

        for (var i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }

        return axios.post(this.getUrlDomain("uploadDocumentsMulti"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async sendMailConfDownloadCedolino(user, v_anno, v_mese, v_id_tipo_folder) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_anno", v_anno);
        formData.append("v_mese", v_mese);
        formData.append("v_id_tipo_folder", v_id_tipo_folder);

        return axios.post(this.getUrlDomain("sendMailConfDownloadCedolino"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateFileStatus(file_path) {

        let formData = new FormData();

        formData.append("file_path", file_path);

        return axios.post(this.getUrlDomain("updateFileStatus"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getEMailsSU(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("getEMailsSU"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async sendMailNotifyDownloadCedolini(file_path, username) {

        let formData = new FormData();

        formData.append("file_path", file_path);
        formData.append("username", username);

        return axios.post(this.getUrlDomain("sendMailNotifyDownloadCedolini"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async logError(username, v_xhr, v_code, v_error, v_url_request, v_params_request) {

        let formData = new FormData();

        formData.append("username", username);
        formData.append("v_xhr", v_xhr);
        formData.append("v_code", v_code);
        formData.append("v_error", v_error);
        formData.append("v_url_request", v_url_request);
        formData.append("v_params_request", v_params_request);

        return axios.post(this.getUrlDomain("logErrorDoxweb"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async sendMailCudAlert(ids, v_companyid) {

        let formData = new FormData();

        formData.append("ids", ids);
        formData.append("v_companyid", v_companyid);

        return axios.post(this.getUrlDomain("sendMailCudAlert"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getInfoUserCud(user, v_company_id, v_year) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_company_id", v_company_id);
        formData.append("v_year", v_year);
     
        return axios.post(this.getUrlApiDomain("getInfoUserCud"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async sendFileByMail(v_subject, v_email, v_file_name, v_full_path) {

        let formData = new FormData();

        formData.append("v_subject", v_subject);
        formData.append("v_email", v_email);
        formData.append("v_file_name", v_file_name);
        formData.append("v_full_path", v_full_path);

        return axios.post(this.getUrlDomain("sendFileByMail"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async saveDataFileExpire(v_date_expire, v_days_berfore_expire, v_file_path) {

        let formData = new FormData();

        formData.append("v_date_expire", v_date_expire);
        formData.append("v_days_berfore_expire", v_days_berfore_expire);
        formData.append("v_file_path", v_file_path);

        return axios.post(this.getUrlDomain("saveDataFileExpire"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getDataFileExpire(v_file_path) {

        let formData = new FormData();

        formData.append("v_file_path", v_file_path);

        return axios.post(this.getUrlDomain("getDataFileExpire"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteDataFileExpire(v_file_path) {

        let formData = new FormData();

        formData.append("v_file_path", v_file_path);

        return axios.post(this.getUrlDomain("deleteDataFileExpire"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async sendMailPagamentoCedoliniAlert(ids, year, month, v_companyid) {

        let formData = new FormData();

        formData.append("ids", ids);
        formData.append("year", year);
        formData.append("month", month);
        formData.append("v_companyid", v_companyid);

        return axios.post(this.getUrlDomain("sendMailPagamentoCedoliniAlert"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteLogSplit(ids) {

        let formData = new FormData();

        formData.append("ids", ids);

        return axios.post(this.getUrlDomain("deleteLogSplit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getActiveProcedureSplit(ids) {

        let formData = new FormData();

        formData.append("ids", ids);

        return axios.post(this.getUrlDomain("getActiveProcedureSplit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async activateProcedureSplit(v_code) {

        let formData = new FormData();

        formData.append("v_code", v_code);

        return axios.post(this.getUrlDomain("activateProcedureSplit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async lunchProcessSplit(v_code) {

        let formData = new FormData();

        formData.append("v_code", v_code);

        return axios.post(this.getUrlDomain("lunchProcessSplit"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async loadFilePdf(v_token, v_path) {

        let formData = new FormData();

        formData.append("v_token", v_token);
        formData.append("v_path", v_path);

        return axios.post(this.getUrlDomain("loadFilePdf"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteTempPdfFile(v_nomefile) {

        let formData = new FormData();

        formData.append("v_nomefile", v_nomefile);
  
        return axios.post(this.getUrlDomain("deleteTempPdfFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async step1CopyPdfOrigInCache(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
  
        return axios.post(this.getUrlDomain("step1CopyPdfOrigInCache"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async step2SplitPdfBusteInDox(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
  
        return axios.post(this.getUrlDomain("step2SplitPdfBusteInDox"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async verifyFolderContent(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
  
        return axios.post(this.getUrlDomain("verifyFolderContent"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFolderContent(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
  
        return axios.post(this.getUrlDomain("deleteFolderContent"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APIDoxweb({
    url: main_domain
})
