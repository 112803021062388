<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center change_psw_init"
        style="max-width: 300px;min-width:300px;">




        <v-form style="min-width:300px;" ref="form" v-model="valid" lazy-validation>



            <div>

                <v-text-field id="newPas" type="password" v-model="password" :rules="passwordRules" label="Nuova password"
                    required>
                </v-text-field>
                <img @click="showPassword" class="showpwd2" src="@/assets/icons8-eye-24.png" />

            </div>



            <div style="margin-top: -15px;">

                <v-text-field type="password" id="confPas" v-model="rePassword" :rules="[passwordConfirmationRule]"
                    label="Conferma password" required>
                </v-text-field>
                <img @click="showPassword3" class="showpwd3" src="@/assets/icons8-eye-24.png" />

            </div>






            <div class="padding" style="min-height:100px;"></div>
        </v-form>
    </v-container>
</template>



<script>
import apiprofile from "../utils/profile/apiprofile";
import apidoxweb from "../utils/doxweb/apidoxweb";

import { bus } from "../main";
import router from ".././router";

import $ from 'jquery';


export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },



    computed: {
        passwordConfirmationRule() {
            return () => (this.password === this.rePassword) || 'La password non corrisponde'
        }

    },

    mounted: function () {

        /*     let self = this;
            fetch('https://jsonplaceholder.typicode.com/posts')
              .then(response => response.json())
              .then(json => self.$data.items = json) */

        this.$root.$children[0].refreshMenu();

        //var res = apiprofile.getProfile(1,2);
        setTimeout(() => {
            this.syncProfile();
            //  var username = window.$cookies.get("username");
            // this.profile.username = username ;


        }, 100);
        // console.log (res);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {
                case "btn_save_password":


                    this.validate();

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({ path: "/dash" });
                    break;

                default:
                    break;
            }
        });

        this.setupButtons();
    },
    data: () => ({

        rePassword: "",
        oldpassword: "",
        password: "",





        passwordRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8) || 'minimo 8 caratteri',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',
            (value) => (value && /[a-z]/.test(value)) || 'Almeno un carattere minuscolo',
            (value) => (value && /[0-9]/.test(value)) || 'Almeno un numero',
            (value) => (value && /[!@#$%^&*+]/.test(value)) || 'Almeno un carattere speciale ! @ # $ % ^ & * +',

        ],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",



        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,

    }),

    methods: {

        showPassword() {

            var newPasType = $("#newPas").attr("type");

            console.log(newPasType);

            if (newPasType == "password") {
                $("#newPas").attr("type", "text");
            }
            else {
                $("#newPas").attr("type", "password");
            }

        },


        showPassword3() {

            var confPasType = $("#confPas").attr("type");

            console.log(confPasType);

            if (confPasType == "password") {
                $("#confPas").attr("type", "text");
            }
            else {
                $("#confPas").attr("type", "password");
            }



        },




        setupButtons: async function () {



            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [
                    {

                      
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save_password",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_doxweb.png",
                        title: "Salva",
                        width: 30


                    },


                    // {
                    //   text: "Dashboard",
                    //   icon: "mdi-home-circle",
                    //   link: "/dash",
                    //   id: "btn_exit",
                    //   disabled: false,
                    // },


                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                //   this.$root.$children[0].$refs.btn_add_tms;

                // console.log( "ok" ,  this.$root.$refs.mainApp );
                //this.$root.$refs.mainApp.bnavbuttons = pulsantis;

                //   refs.btn_add_tms
                // console.log ("Refs" , this.$root.$children[0].$refs.btn_add_tms );

                // var refs = this.$root.$children[0].$refs.btn_add_tms ;

                //   var add_tms = refs.btn_add_tms ;

                //d console.log(this.$root.$children[0].$refs.btn_add_tms);
                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);



        },



        checkPassword: async function () {

            var that = this;

            var username = window.$cookies.get("username");
            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            that.profile.newpassword = this.password;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprofile.checkPassword(
                username,
                that.profile
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkPassword", res);


                if (res.data.Result == "OK") {

                    console.log("ok");

                    that.savePassword();

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        savePassword: async function () {

            var that = this;

            var v_id = window.$cookies.get("doxweb_id_folder_chg_psw");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.changePswFolder(
                v_id,
                this.password
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from changePswFolder", res);

                that.$swal({
                    icon: "success",
                    text: "Password changed successfully",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    router.push({
                        path: "/initDocuments"
                    });

                }, 2000);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },


        savePasswordOld: async function () {

            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {


                this.profile.oldpassword = "password";
                this.profile.newpassword = this.password;


                response = await apiprofile.savePasswordNew(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {

                this.showSpinner = false;
                console.log(error);

                this.$swal({
                    icon: "error",
                    text: error.message,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {

                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "La password è stata cambiata con successo",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {


                    window.$cookies.set("is_pwd_changed_once", 1, "9y");


                    console.log("PSW NEW", this.password);

                    var v_enc_psw = btoa(this.password);

                    console.log("ENC PSW NEW", v_enc_psw);

                    window.$cookies.set("token_system", v_enc_psw, "9y");

                    this.$root.$children[0].refreshMenu();


                    router.push({
                        path: "/dash"
                    });

                }, 300);




            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },




        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
             var system = window.$cookies.get("system"); */

            setTimeout(async () => {


                var username = window.$cookies.get("username");
                var token_system = window.$cookies.get("token_system");

                //   console.log ( system );
                this.showSpinner = true;
                this.$root.$children[0].showProgress = true;


                /*     var prove ;
                    try {
                
                        prove = await apiprofile.getProfile(
                            username,
                            token_system
                            );
                
                    } catch (error) {
                        alert ( error.message );
                        console.log(prove);
                
                    } */




                var result;
                try {
                    // alert ( username + " +" + token_system );
                    result = await apiprofile.getProfile(
                        username,
                        token_system
                    ).then((response) => {
                        // alert ("finshed");

                        // console.log (response);
                        this.showSpinner = false;
                        this.$root.$children[0].showProgress = false;

                        try {

                            this.profile = response.data.Result;
                            this.profile.username = username;
                            //console.log("HERE",response.data.Result.iban);
                            /*         this.iban = response.data.Result.iban ;
                                    this.email = response.data.Result.mail ;
                                    this.codicefiscale = response.data.Result.codice_fiscale ;
                                    this.cellulare = response.data.Result.tel_cell ;
                                    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ; */
                            this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0);


                            //this.monthArray = response.data.Timesheet;


                        } catch (error) {
                            console.log(error);
                            alert(error.message);
                        }

                        console.log(result);




                    });
                } catch (error) {
                    alert(error.message);

                    this.showSpinner = false;
                    this.$root.$children[0].showProgress = false;
                }

            }, 300);

            //  this.data.days = response.data ;
        },



        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
          console.log('a phone number was clicked');
        }
    ,
     */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {

                this.$swal({
                    icon: "error",
                    text: "Check data",
                    showConfirmButton: false,
                    timer: 2000
                });

            } else {

                console.log("Profile: ", this.profile);

                this.savePassword();

                //this.checkPassword();

            }
        },



        reset() {
            this.$refs.form.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;

}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}


/* .change_psw_init .v-input {
    font-size: 1.2em !important;
} */

.change_psw_init .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}



.change_psw_init .v-select {
    font-size: 1.2em !important;
}

/* .change_psw_init .v-label {
    font-size: 1em !important;
} */

.change_psw_init .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}
</style>
