<template>
    <div class="w-container-spesa v-overflow editUtente editSpesa"
        style="padding-top: 84px;background-color: rgb(255 255 255 / 50%);">
    </div>
</template>

<script>

/* eslint-disable */
import { createWorker, PSM, OEM } from 'tesseract.js';
const worker = createWorker({
    logger: m => console.log(m),
});

import {
    bus
} from "../main";

import router from ".././router";
import apiusers from "../utils/users/apiusers";

export default ({
    mounted() {

        /*eslint-disable no-undef*/



        window.$cookies.set("filter_company_pratiche", "", "9y");
        window.$cookies.set("filter_search_text_pratiche", "", "9y");
        window.$cookies.set("filter_tipo_progetto_pratiche", "", "9y");
        window.$cookies.set("filter_tipo_controllo_pratiche", "", "9y");
        window.$cookies.set("filter_stato_pratiche", "", "9y");
        window.$cookies.set("filter_referente_pratiche", "", "9y");
        window.$cookies.set("filter_gestore_pratiche", "", "9y");
        window.$cookies.set("filter_pagamento_pratiche", "", "9y");
        window.$cookies.set("filter_intest_mandato_pratiche", "", "9y");
        window.$cookies.set("filter_data_da_pratiche", "", "9y");
        window.$cookies.set("filter_data_a_pratiche", "", "9y");
        window.$cookies.set("filter_anno_pratiche", "", "9y");

        router.push({
            path: "/pratiche"
        });


    },


    data: () => ({

        showPicker: false,

        imageB64: "",
        result: "",
        valoreImporto: null,

        showCamera: true,

        showForm: true,

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "Aggiungi Spesa",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
        prefissi: [],

        spese: {},

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,



        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {

        getLastDayMonth: function () {

            var date = new Date();

            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            var elements = lastDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },

        invoiceSuppliersOld() {

            var that = this;

            var lastDay = this.getLastDayMonth();

            window.$cookies.set("sel_filter_inv_date_scad_from", "", "9y");
            window.$cookies.set("sel_filter_inv_date_scad_to", lastDay, "9y");

            window.$cookies.set("sel_filter_inv_date_emiss_from", "", "9y");
            window.$cookies.set("sel_filter_inv_date_emiss_to", "", "9y");

            window.$cookies.set("sel_filter_azienda_fatture", 7, "9y");

            router.push({
                path: "/fattureFornitori"
            });

        },


        invoiceSuppliers: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getInfoUser(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoUser", res);


                var lastDay = that.getLastDayMonth();

                window.$cookies.set("sel_filter_inv_date_scad_from", "", "9y");
                window.$cookies.set("sel_filter_inv_date_scad_to", lastDay, "9y");

                window.$cookies.set("sel_filter_inv_date_emiss_from", "", "9y");
                window.$cookies.set("sel_filter_inv_date_emiss_to", "", "9y");

                window.$cookies.set("sel_filter_azienda_fatture", res.data.infouser.idcompany_logo, "9y");
                window.$cookies.set("sel_filter_user_is_demo", res.data.infouser.is_demo, "9y");

                router.push({
                    path: "/fattureFornitori"
                });



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },


    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-spesa {
    width: 100%;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editUtente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editUtente .v-input {
    font-size: 1.2em !important;
}

.editUtente .v-select {
    font-size: 1.2em !important;
}

.editUtente .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}




@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}

#imgPreview {
    display: none;
}


.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-left: auto;


    display: flex;
    justify-content: center;
    align-items: center;


}


.editSpesa .v-form {
    overflow-y: auto;
    max-height: 100vh;
    padding-bottom: 300px;
    overflow-x: hidden;
    margin-top: 10px;

}

.dropify-wrapper .dropify-message {
    top: 25%;
}

#d_importo {
    padding: 16px;
    max-width: 500px;
}


@media screen and (min-width: 768px) {
    .editSpesa .v-form {
        max-width: 480px !important;
        margin: 0 auto;
        padding-top: 13px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 47px;


    }

    #d_importo {
        transform: translateX(10px);
    }
}


@media screen and (max-width: 768px) {
    .dropify-wrapper {
        margin-left: 0px !important;
    }
}

#preview {
    display: none;
}


.active-row {
    background: transparent !important;
    border: 1px solid #00000087 !important;
}


.v-progress-circular__info {
    min-width: 100px;
    transform: translateY(-32px);
    background: white;
}

.v-progress-circular {
    transform: translateY(44px);
}

.span.dropify-filename-inner {
    display: none;
}

.dropify-filename {
    display: none;
}
</style>
