import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APILogoFirma {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async saveLogo(user, file, logo_present, testo_firma, testo_mittente) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("file", file);
    formData.append("logo_present", logo_present);
    formData.append("testo_firma", testo_firma);
    formData.append("testo_mittente", testo_mittente);

    return axios.post(this.getUrlDomain("EmtSaveLogoFirma"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getLogo(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtGetLogoFirma"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDaysPwd(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtGetDaysPwd"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveDaysPwd(user, num_days) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("num_days", num_days);

    return axios.post(this.getUrlDomain("EmtSaveDaysPwd"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APILogoFirma({
  url: "https://services.ebadge.it/public/api/"
})
