import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIReminderHolidays {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async saveReminderHolidays(user, reminder, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  id);

    for ( var key in reminder ) {
      formData.append(key, reminder[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtSaveConfRemHolidaysApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getReminder(id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  id);

   
    return axios.post(this.getUrlDomain("EmtGetConfRemHolidaysApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteReminder(id)
  {

    let formData = new FormData();

    formData.append("v_id",  id);

    return axios.post(this.getUrlDomain("EmtDelConfRemHolidaysApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APIReminderHolidays({
  url: "https://services.ebadge.it/public/api/"
})
