import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITimesheet {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  
  async calcOvtAbs(val_day, val_forzatura, hour_ordinario, cur_year, cur_month, user_sel, user) {

    let formData = new FormData();

    formData.append("v_forzatura", val_forzatura);
    formData.append("v_day", val_day);
    formData.append("v_user_sel", user_sel);
    formData.append("v_h_ord", hour_ordinario);
    formData.append("v_cur_year", cur_year);
    formData.append("v_cur_month", cur_month);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtCalcOvertimeAbsenceApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async saveAndOpen(cur_year, cur_month, user_sel, timesheet, user) {

    let formData = new FormData();

    formData.append("v_user_sel", user_sel);
    formData.append("v_cur_year", cur_year);
    formData.append("v_cur_month", cur_month);
    formData.append("v_timesheet", timesheet);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtSaveDataAndOpenApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveAndClose(cur_year, cur_month, user_sel, timesheet, user) {

    let formData = new FormData();

    formData.append("v_user_sel", user_sel);
    formData.append("v_cur_year", cur_year);
    formData.append("v_cur_month", cur_month);
    formData.append("v_timesheet", timesheet);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtSaveDataAndCloseApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveData(cur_year, cur_month, user_sel, timesheet, user) {

    let formData = new FormData();

    formData.append("v_user_sel", user_sel);
    formData.append("v_cur_year", cur_year);
    formData.append("v_cur_month", cur_month);
    formData.append("v_timesheet", timesheet);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtSaveDataApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async pdfAmministrativo(cur_year, cur_month, login_res_sel, user) {

    let formData = new FormData();

    formData.append("v_year", cur_year);
    formData.append("v_month", cur_month);
    formData.append("v_login_res", login_res_sel);
    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("EmtPdfAmministrativeApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async pdfAmministrativoWithProj(cur_year, cur_month, login_res_sel, user, v_proj) {

    let formData = new FormData();

    formData.append("v_year", cur_year);
    formData.append("v_month", cur_month);
    formData.append("v_login_res", login_res_sel);
    formData.append("v_user", user);
    formData.append("v_proj", v_proj);

    return axios.post(this.getUrlDomain("EmtPdfAmministrativeWithProjApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async pdfAmministrativoEbadge(cur_year, cur_month, login_res_sel, user) {

    let formData = new FormData();

    formData.append("v_year", cur_year);
    formData.append("v_month", cur_month);
    formData.append("v_login_res", login_res_sel);
    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("EmtPdfAmministrativeEbadgeApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async pdfCompleto(cur_year, cur_month, login_res_sel, user) {

    let formData = new FormData();

    formData.append("v_year", cur_year);
    formData.append("v_month", cur_month);
    formData.append("v_login_res", login_res_sel);
    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("EmtPdfCompleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async pdfCompletoWithPrj(cur_year, cur_month, login_res_sel, user, v_project) {

    let formData = new FormData();

    formData.append("v_year", cur_year);
    formData.append("v_month", cur_month);
    formData.append("v_login_res", login_res_sel);
    formData.append("v_user", user);
    formData.append("v_project", v_project);

    return axios.post(this.getUrlDomain("EmtPdfCompleteWithProjApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async pdfCompletoEbadge(cur_year, cur_month, login_res_sel, user) {

    let formData = new FormData();

    formData.append("v_year", cur_year);
    formData.append("v_month", cur_month);
    formData.append("v_login_res", login_res_sel);
    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("EmtPdfCompleteEbadgeApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDipendenti(azienda, user, year, month) {

    let formData = new FormData();

    formData.append("v_azienda", azienda);
    formData.append("v_user", user);
    formData.append("v_year", year);
    formData.append("v_month", month);


    return axios.post(this.getUrlDomain("tsGetDipendenti"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDipendentiByUser(user, year, month) {

    let formData = new FormData();

    formData.append("v_user", user);
    formData.append("v_year", year);
    formData.append("v_month", month);


    return axios.post(this.getUrlDomain("tsGetDipendentiByUser"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAziendaByUser(user) {

    let formData = new FormData();

    formData.append("v_user", user);


    return axios.post(this.getUrlDomain("tsGetAziendaByUser"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateMultiStatus(user, year, month, user_id, status) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_status", status);
    formData.append("v_year", year);
    formData.append("v_month", month);
    formData.append("v_user_id", user_id);


    return axios.post(this.getUrlDomain("EmtCheckTimesheetUpdateMultiStatusApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async multiDelete(user, year, month, user_id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_anno", year);
    formData.append("v_mese", month);
    formData.append("id", user_id);


    return axios.post(this.getUrlDomain("EmtCheckTimesheetMultiDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getIdProgettoAssociato(v_login) {

    let formData = new FormData();

    formData.append("v_login", v_login);

    return axios.post(this.getUrlDomain("EmtTsGetIdProgettoAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getProgettiAzienda(v_login) {

    let formData = new FormData();

    formData.append("v_login", v_login);

    return axios.post(this.getUrlDomain("EmtTsGetProgAziApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async invitoClientiFornitori(user_group, user, sender, title, msg, isgroup, file, mailaddress, name, surname, enable_signature, v_ids, companyid, year_sel, month_sel, mail_cc) {

    let formData = new FormData();

    if (file && (file != undefined)) {

      formData.append("file", file[0]);

    }

    formData.append("user_group", user_group);
    formData.append("user", user);
    formData.append("sender", sender);
    formData.append("title", title);
    formData.append("msg", msg);
    formData.append("isgroup", isgroup);
    formData.append("send_notify", 0);
    formData.append("productid", "emt");
    formData.append("mailaddress", mailaddress);
    formData.append("name", name);
    formData.append("surname", surname);
    formData.append("enable_signature", enable_signature);
    formData.append("is_users", v_ids);
    formData.append("id_company", companyid);
    formData.append("year_sel", year_sel);
    formData.append("month_sel", month_sel);
    formData.append("mailaddresscc", mail_cc);

    return axios.post(this.getUrlDomain("EmtCheckTimesheetCustSupSendInviteApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async createPerformancePDF(id, from_month, to_month, user, id_azienda) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("from_month", from_month);
    formData.append("to_month", to_month);
    formData.append("username", user);
    formData.append("companyid", id_azienda);

    return axios.post(this.getUrlDomain("EmtPdfPerformanceApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateSettingsPerformanceTimesheet(scostamento, user) {

    let formData = new FormData();

    formData.append("scostamento", scostamento);
    formData.append("username", user);
 
    return axios.post(this.getUrlDomain("EmtUpdateSettPerformTimesheet"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initPerformSettings(user) {

    let formData = new FormData();

    formData.append("username", user);
 
    return axios.post(this.getUrlDomain("EmtLoadSettPerformTimesheet"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async calcOvtAbsEbadge(val_day, v_login, v_anno, v_mese, v_entrata1, v_uscita1, v_entrata2, v_uscita2) {

    let formData = new FormData();

    formData.append("v_day", val_day);
    formData.append("v_login", v_login);
    formData.append("v_entrata1", v_entrata1);
    formData.append("v_uscita1", v_uscita1);
    formData.append("v_entrata2", v_entrata2);
    formData.append("v_uscita2", v_uscita2);
    formData.append("v_anno", v_anno);
    formData.append("v_mese", v_mese);

    return axios.post(this.getUrlDomain("EmtCalcOvertimeAbsenceEbadge"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveDataEbadge(cur_year, cur_month, user_sel, timesheet) {

    let formData = new FormData();

    formData.append("sel_username_ts", user_sel);
    formData.append("v_cur_year", cur_year);
    formData.append("v_cur_month", cur_month);
    formData.append("v_timesheet", timesheet);

    return axios.post(this.getUrlDomain("EmtSaveDataTsEbadge"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteRowEbadge(user_sel, v_days, v_month, v_year) {

    let formData = new FormData();

    formData.append("sel_username_ts", user_sel);
    formData.append("v_days", v_days);
    formData.append("v_month", v_month);
    formData.append("v_year", v_year);

    return axios.post(this.getUrlDomain("EmtDeleteRowTsEbadge"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteFile(v_path) {

    let formData = new FormData();

    formData.append("v_path", v_path);

    return axios.post(this.getUrlDomain("EmtDeleteFileApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getProgetti(v_login) {

    let formData = new FormData();

    formData.append("v_login", v_login);

    return axios.post(this.getUrlDomain("EmtTsGetProgettiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




}

export default new APITimesheet({
  url: "https://services.ebadge.it/public/api/"
})
