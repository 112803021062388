<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_agila_controlli">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Nome</th>
                                        <th class='all'>Descrizione</th>
                                        <th class='all'>Tipo</th>
                                        <th class='all'>Gruppo</th>
                                        <th class='all'>Sincronizzato il</th>
                                        <th class='all'>Descrizione Storico</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="8">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                                        max-width="24" max-height="24" style="margin-right:13px"
                                        transition="scale-transition" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>


                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetProjectType">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetProjectType = !sheetProjectType">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Tipo Progetto
                    </div>
                    <v-list-item v-for="prj in projectType" :key="prj.id" @click="filterProjectType(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                                prj.nome
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetControlType">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetControlType = !sheetControlType">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Tipo
                    </div>
                    <v-list-item v-for="prj in controlType" :key="prj.id" @click="filterControlType(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                                prj.descrizione
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Stato
                    </div>
                    <v-list-item v-for="prj in stati" :key="prj.id" @click="filterStatus(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                                prj.nome
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

    </div>
</template>


<script>
import apisedioperative from "../utils/sedioperative/apisedioperative";
import apicontrolli from "../utils/controlli/apicontrolli";

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        // used for load users associated to group
        window.$cookies.set("is_load_users_from_group", 1, "9y");

        var pointerVue = this;

        window.curSrcText = "";

        window.idPratica = this.decodeString(this.$route.params.id);





        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        $(document).ready(
            function () {

                $("body").off();

            }
        );


        $(document).ready(function () {





            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });

            $('body').on('click', '.seltipoprogetto', function () {

                console.log(("EVENTO CLICK SELTIPOPROGETTO"));

                pointerVue.sheetProjectType = true;

            });

            $('body').on('click', '.seltipo', function () {

                console.log(("EVENTO CLICK SELTIPO"));

                pointerVue.sheetControlType = true;

            });

            $('body').on('click', '.selstato', function () {

                console.log(("EVENTO CLICK SELSTATO"));

                pointerVue.sheetStatus = true;

            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));


            });


        });



        // setTimeout(() => {

        //     this.syncAziende();

        // }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            var ids = "";

            switch (data) {

                case "btn_delete":


                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":



                    var v_id = btoa("0") + "l";


                    router.push({
                        path: "/editControllo/" + v_id
                    });




                    break;

                case "btn_edit":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    ids = btoa(ids) + "l";

                    router.push({
                        path: "/editControllo/" + ids
                    });



                    break;

                case "btn_back":

                    var v_id_prat = window.$cookies.get("id_edit_pratica");


                    router.push({
                        path: "/editPratica/" + v_id_prat
                    });



                    break;

                case "btn_ctr_cond":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    ids = btoa(ids) + "l";

                    router.push({
                        path: "/controlliCondizioni/" + ids
                    });


                    break;

                case "btn_del_ctr_cond":

                    this.deleteConditions();


                    break;

                case "btn_export_xls":

                    this.exportXls();


                    break;

                default:
                    break;

            }
        });


        this.setupButtonsGrid();

        window.start_ = 0;

        //var pointerVue = this;


        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({


        sheetStatus: false,

        sheetProjectType: false,

        sheetControlType: false,

        sheetAziende: false,
        search: "",

        textSearch: "",

        aziende: [],

        projectType: [],

        controlTypeTemp: [],

        controlType: [],

        stati: [{ id: "", nome: "Tutti" }, { id: "Y", nome: "Si" }, { id: "N", nome: "No" }, { id: "1", nome: "NA" }, { id: "2", nome: "WIP" }],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

    },


    methods: {

        decodeString: function (value) {

            var v = value.substring(0, value.length - 1);

            console.log("VAL: ", v);

            return atob(v);

        },

        setTipoControlli: function (id_tipo_progetto) {

            console.log("AZI SEL 2: ", id_tipo_progetto);

            console.log("DIP TMP: ", this.controlTypeTemp);

            var TipoControlli = [];

            if (id_tipo_progetto != "") {

                TipoControlli.push({
                    id: "",
                    descrizione: "Tutti"
                });

                for (var i = 0; i < this.controlTypeTemp.length; i++) {

                    if (parseInt(this.controlTypeTemp[i].tipo_progetto_id) == parseInt(id_tipo_progetto)) {

                        TipoControlli.push({
                            id: this.controlTypeTemp[i].id,
                            descrizione: this.controlTypeTemp[i].descrizione
                        });

                    }

                }


            }


            console.log("DIP SEL: ", TipoControlli);

            this.controlType = TipoControlli;

        },



        filterStatus: function (statusId) {


            console.log("STATUS SEL: ", statusId);

            this.sheetStatus = false;

            window.curStatus = statusId;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        filterControlType: function (ctrTypeId) {


            console.log("CTR TYPE SEL: ", ctrTypeId);

            this.sheetControlType = false;

            window.curCtrType = ctrTypeId;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },





        filterProjectType: function (prjTypeId) {


            console.log("PRJ TYPE SEL: ", prjTypeId);

            this.sheetProjectType = false;

            window.curPrjType = prjTypeId;

            this.hideShowButtons(false);

            this.setTipoControlli(prjTypeId);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicontrolli.initDDL(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initDDL", res);

                try {

                    this.aziende = res.data.filtro_aziende;

                    this.projectType = res.data.filtro_tipo_proj;

                    this.controlTypeTemp = res.data.tipo_controllo;

                    window.ddlAziende = res.data.company;

                    window.ddlTipoControllo = res.data.tipo_controllo;

                    window.ddlGruppi = res.data.gruppi;

                    window.ddlPratiche = res.data.pratiche;

                    window.ddlTipoProgetto = res.data.tipo_progetto;






                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },


        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }


            });

            setTimeout(() => {

                var pulsantis = [];

                // if (tot == 0) {

                //     pulsantis.push({
                //         text: "Add",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                //         link: "/settings",
                //         id: "btn_add",
                //         disabled: false,
                //         title: "Aggiungi",
                //         width: 30

                //     });


                // }



                // if (tot == 1) {

                //     pulsantis.push({
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                //         link: "/info",
                //         id: "btn_edit",
                //         disabled: false,
                //         title: "Modifica",
                //         width: 30
                //     });

                //     pulsantis.push({
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon-controls-conditions-emt.png",
                //         link: "/info",
                //         id: "btn_ctr_cond",
                //         disabled: false,
                //         title: "Condizioni",
                //         width: 30
                //     });




                // }

                // if (tot > 0) {


                //     pulsantis.push({
                //         text: "Delete",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                //         link: "/info",
                //         id: "btn_delete",
                //         disabled: false,
                //         title: "Elimina",
                //         width: 30
                //     });

                //     pulsantis.push({
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon-eraser-emt.png",
                //         link: "/info",
                //         id: "btn_del_ctr_cond",
                //         disabled: false,
                //         title: "Elimina Condizioni",
                //         width: 30
                //     });

                // }

                // pulsantis.push({
                //     text: "Esporta XLS",
                //     icon: "mdi-arrow-left",
                //     image: "https://app.emtool.eu/public/_lib/img/icon-export-xls-emt.png",
                //     link: "/confOrariChoice",
                //     id: "btn_export_xls",
                //     disabled: false,
                //     title: "Esporta Excel",
                //     width: 30
                // });


                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 30
                });



                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },


        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {



            var pointerVue = this;



            const date = new Date().toJSON().slice(0, 10)



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                //  var v_company = window.$cookies.get("sel_filter_sedi_oper");

                console.log("TOKEN: ", v_token);
                //  console.log("V_COMPANY: ", v_company);


                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        name: 'sel_row',
                        width: '1%'
                    },

                    {
                        data: 'row_id',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'nome',
                        orderable: false,
                        name: 'nome',
                        width: '4%'
                    },

                    {
                        data: 'descrizione',
                        orderable: false,
                        name: 'descrizione',
                        width: '4%'
                    },

                    {
                        data: 'desc_tipo',
                        orderable: false,
                        name: 'desc_tipo',
                        width: '4%'
                    },

                    {
                        data: 'desc_group',
                        orderable: false,
                        name: 'desc_group',
                        width: '4%'
                    },

                    {
                        data: 'date_history',
                        orderable: false,
                        name: 'date_history',
                        width: '4%'
                    },

                    {
                        data: 'descrizione_storico',
                        orderable: false,
                        name: 'descrizione_storico',
                        width: '4%'
                    },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,


                    ajax: {
                        url: pointerVue.getUrlDomain("EmtAgilaHistoryCtrlsApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start_ = window.perpage;

                            valori.start_ = 0;
                            valori.length_ = window.perpage;

                            valori.curSrcText = window.curSrcText;

                            valori.idPratica = window.idPratica;



                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 1000);


                        var n_pratica = window.$cookies.get("nome_pratica");
                        $(".text_candidate").html("Pratica: " + n_pratica);


                        var n_cliente = window.$cookies.get("nome_cliente");
                        $(".text_cliente").html("Cliente: " + n_cliente);






                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start_ = window.start_;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EmtAgilaHistoryCtrlsApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start_ += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Storico Controlli</div>
                
                    <div class='text_cliente'></div>

                    <div class='text_candidate'></div>

                </div>
                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });

                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_agila_controlli tr', function () {


                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    // pulsantis.push({
                    //     text: "Add",
                    //     icon: "mdi-home-circle",
                    //     image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                    //     link: "/settings",
                    //     id: "btn_add",
                    //     disabled: false,
                    //     title: "Aggiungi",
                    //     width: 30
                    // });

                    // if (ids != "") {

                    //     pulsantis.push({
                    //         text: "Delete",
                    //         icon: "mdi-home-circle",
                    //         image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                    //         link: "/info",
                    //         id: "btn_delete",
                    //         disabled: false,
                    //         title: "Elimina",
                    //         width: 30

                    //     });



                    // }

                    // pulsantis.push({
                    //     text: "Esporta XLS",
                    //     icon: "mdi-arrow-left",
                    //     image: "https://app.emtool.eu/public/_lib/img/export-xls-emt.png",
                    //     link: "/confOrariChoice",
                    //     id: "btn_export_xls",
                    //     disabled: false,
                    //     title: "Esporta Excel",
                    //     width: 30
                    // });

                    pulsantis.push({
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        link: "/confOrariChoice",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro",
                        width: 30
                    });



                }

                if (v_enable == true) {


                    // pulsantis.push({
                    //     text: "Add",
                    //     icon: "mdi-home-circle",
                    //     image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                    //     link: "/settings",
                    //     id: "btn_add",
                    //     disabled: false,
                    //     title: "Aggiungi",
                    //     width: 30
                    // });

                    // pulsantis.push({
                    //     text: "Edit",
                    //     icon: "mdi-home-circle",
                    //     image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                    //     link: "/info",
                    //     id: "btn_edit",
                    //     disabled: false,
                    //     title: "Modifica",
                    //     width: 30

                    // });

                    // if (ids != "") {

                    //     pulsantis.push({
                    //         text: "Delete",
                    //         icon: "mdi-home-circle",
                    //         image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                    //         link: "/info",
                    //         id: "btn_delete",
                    //         disabled: false,
                    //         title: "Elimina",
                    //         width: 30

                    //     });



                    // }


                    pulsantis.push({
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        link: "/confOrariChoice",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro",
                        width: 30
                    });





                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                // var pulsantis = [

                //     {
                //         text: "Add",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                //         link: "/settings",
                //         id: "btn_add",
                //         disabled: false,
                //         width: 30
                //     },

                //     {
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                //         link: "/info",
                //         id: "btn_edit",
                //         disabled: false,
                //         width: 30
                //     },

                //     {
                //         text: "Delete",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                //         link: "/info",
                //         id: "btn_delete",
                //         disabled: false,
                //         width: 30
                //     },

                //     {
                //         text: "Esporta XLS",
                //         icon: "mdi-arrow-left",
                //         image: "https://app.emtool.eu/public/_lib/img/icon-export-xls-emt.png",
                //         link: "/confOrariChoice",
                //         id: "btn_export_xls",
                //         disabled: false,
                //         title: "Esporta Excel",
                //         width: 25
                //     },

                //     {
                //         text: "Dashboard",
                //         icon: "mdi-home-circle",
                //         link: "/dash",
                //         id: "btn_exit",
                //         disabled: false,
                //     },

                // ];

                pulsantis = [];

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 30
                });


                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        getInfoAzienda: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisedioperative.getInfoAzienda(
                window.$cookies.get("sel_filter_sedi_oper"),
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoAzienda", res);


                $("#divDescAzi").html(res.data.azienda.fld_name);






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        deleteRow: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            ids = btoa(ids) + "l";

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicontrolli.deleteControlli(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteControlli", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();


                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },



        deleteConditions: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            ids = btoa(ids) + "l";

            that.$swal
                .fire({
                    title: "Elimina Condizioni",
                    html: "Sei sicuro di voler eliminare le condizioni dai controlli selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicontrolli.deleteCondizioni(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteCondizioni", res);

                            that.$swal({
                                icon: "success",
                                text: "Condizioni Eliminate",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();


                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        exportXls: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicontrolli.exportXls(
                v_token,
                window.curSrcText,
                window.curCompany,
                window.curPrjType,
                window.curCtrType,
                window.curStatus
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from exportXls", res);

                if (res.data.Result == "OK") {



                    window.open(res.data.ValUrl, "_blank");




                }
                else {

                    that.$root.$children[0].showProgress = false;

                    that.$swal({
                        icon: "error",
                        text: res2.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },














    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/FiltroNucleo.png);
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_agila_controlli #myTable {
    margin-right: 3px;
    width: 1785px !important;
}

.cont_agila_controlli table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_agila_controlli table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_agila_controlli th {
    padding-left: 5px !important;
}

.cont_agila_controlli ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_agila_controlli .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_agila_controlli div.dataTables_wrapper div.dataTables_filter input {}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_agila_controlli #myTable_wrapper {
    min-width: initial;
    min-width: 1800px;
    max-width: 1800px;
    width: 1800px;
}

.cont_agila_controlli .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_agila_controlli .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_agila_controlli .checkall {
    width: 13px;
    height: 13px;
}

.cont_agila_controlli .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_agila_controlli .val_status {
    width: 10px !important;
}


.cont_agila_controlli .img_centered {
    text-align: center
}




.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}

.cont_agila_controlli .font-row-grid-datetime {
    font-size: 10px !important;
    width: 58px !important;
}

.cont_agila_controlli .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cl-div-hid {
    display: none;
}

.cont_agila_controlli .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.cont_agila_controlli #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;

}

.cont_agila_controlli .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_agila_controlli .seltipoprogetto {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_agila_controlli .seltipo {
    max-width: 29px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_agila_controlli .selstato {
    max-width: 25px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_agila_controlli .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_agila_controlli .selanno {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
    background-image: url(/emt/img/calendario-giorni-emt.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_agila_controlli .selmese {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
    background-image: url(/emt/img/calendario-mesi-emt.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_agila_controlli .selstato2 {
    max-width: 24px !important;
    min-height: 24px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 24px !important;
    text-indent: -119px !important;
    background-size: 24px !important;
    background-repeat: no-repeat !important;
    background-image: url(/emt/img/filtro-stato-emt.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}


.text_candidate {
    font-weight: bold;
    margin-left: 19px;
}

.text_cliente {
    font-weight: bold;
    margin-left: 19px;
}


@media screen and (max-width: 960px) {

    .cont_agila_controlli .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_agila_controlli #myTable {
        /* margin-top:54px; */
    }

    .cont_agila_controlli #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_agila_controlli #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_agila_controlli #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_agila_controlli .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_agila_controlli #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_agila_controlli .cl_num {
        margin-left: -2px !important;
    }



    .cont_agila_controlli .cl-nome {
        width: 105px;
    }

    .font-row-grid-datetime {
        font-size: 10px !important;
        width: 100px !important;
        display: inline-table !important;
    }

    .cont_agila_controlli .img_centered {
        display: inline;
    }

    .cont_agila_controlli .cl_checkall {
        padding-left: 19px;
        padding-top: 2px;
    }




}
</style>
