<template>
    <v-container class="w-container-edit-scadenza v-overflow edit_scadenza_agila" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#8ec8a3">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <vue-dropzone id="myDropzone" ref="myDropzone" :options="dropzoneOptions"
                        @vdropzone-file-added="fileAdded"></vue-dropzone>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div style="display:flex;justify-content:center">

                        <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_doxweb.png"
                            max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                            transition="scale-transition" title="Cancel" @click="btnCancelEnterPassword" />

                        <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_doxweb.png"
                            max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                            title="Confirm" @click="btnConfirmEnterPassword" />


                    </div>

                </v-col>

            </v-row>

        </v-form>





    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
import apigroups from "../utils/groups/apigroups";
import apiscadenze from "../utils/scadenze/apiscadenze";

import apidoxweb from "../utils/doxweb/apidoxweb";


import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import VueDropzone from 'vue2-dropzone';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/





        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    //this.saveData();

                    this.validate();

                    break;

                case "btn_back":



                    var id_pratica = window.$cookies.get("id_pratica");


                    router.push({
                        path: "/scadenze/" + id_pratica
                    });

                    break;

                case "btn_utenti":

                    window.$cookies.set("sel_filter_azienda_gruppo_utenti", $("#companyid").val(), "9y");
                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupSelUsers"
                    });


                    break;

                case "btn_mail":

                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupMails"
                    });

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {

        });

    },


    data: () => ({

        dropzoneOptions: {
            url: 'http://emtool.local/public/utility/uploadFile.php', // Your upload endpoint
            maxFilesize: 5, // Maximum filesize in MB
            acceptedFiles: '.jpg,.jpeg,.png,.gif,.pdf', // Accepted file formats
            autoProcessQueue: false,
        },

        files: [],

        filesForUpload: [],

        removeValue: "S",

        valorePosizione: null,

        valoreFrequenza: null,

        valoreTempo: null,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,


        controllo: { nome: "", praticaid: "", companyid: "", send_as_mail: "N", send_as_sms: "N", send_as_wapp: "N", enable_history: "N" },

        dipendenti: [],

        dipartimentiTemp: [],

        scopesTemp: [],

        ore: [],

        tipo_progetto: [],

        pratiche: [],

        praticheTemp: [],

        gruppi: [],

        tipo_controllo: [],

        stati: [{ id: "N", name: "No" }, { id: "Y", name: "Si" }, { id: "1", name: "NA" }, { id: "2", name: "WIP" }],

        currAzienda: "",
        titolo: "Aggiungi Scadenza",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Campo richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],

    }),

    components: {
        VueDropzone,
    },


    methods: {

        btnCancelEnterPassword: function () {

        },


        btnConfirmEnterPassword: async function () {

            var that = this;

            //console.log("FILES: ", this.files);

            that.filesForUpload = [];

            if (that.files.length > 0) {


                for (var x = 0; x < that.files.length; x++) {

                    const file = that.files[x]; // Seleziona il primo file (puoi adattarlo per più file)
                    const fileContent = await that.readFile(file);
                    //console.log('Contenuto del file ' + x.toString() + ':', fileContent);

                    var v_file = { name: that.files[x].name, content: fileContent };

                    that.filesForUpload.push(v_file);

                }


            }


            console.log("FILES UPLOADED: ", that.filesForUpload);

            var jsonString = JSON.stringify(that.filesForUpload);

            console.log("FILES UPLOADED JSON: ", jsonString);



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.uploadDocumentsMulti(
                jsonString
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadDocumentsMulti", res);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );









        },

        readFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    resolve(event.target.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsText(file);
            });
        },

        fileAdded(file) {
            this.files.push(file);
        },

        uploadFiles() {
            if (this.files.length > 0) {
                this.$refs.myDropzone.processQueue(); // Initiates file upload
            }
        },

        btnConfirmTempo: function () {

            this.controllo.timebeforesend = this.valoreTempo;

            this.$modal.hide('popupTempo');

            this.setupButtons();

        },


        btnCancelTempo: function () {

            this.$modal.hide('popupTempo');

        },





        chkCancelRemoveValue: function () {

            this.removeValue = "N";

        },


        chkRemoveValue: function () {

            if (this.removeValue == "S") {

                this.controllo.send_as_mail = "N";
                this.controllo.send_as_sms = "N";
                this.controllo.send_as_wapp = "N";

            }

        },

        btnConfirmFrequenza: function () {

            this.controllo.frequency = this.valoreFrequenza;

            this.$modal.hide('popupFrequenza');

            this.setupButtons();

        },


        btnCancelFrequenza: function () {

            this.$modal.hide('popupFrequenza');

        },


        decodeString: function (value) {

            var v = value.substring(0, value.length - 1);

            console.log("VAL: ", v);

            return atob(v);

        },



        enableSaveButton: function () {

            var result = 1;

            console.log("CONTROLLO: ", this.controllo);

            if (this.controllo.companyid == "") {
                result = 0;
            }

            if (result == 1) {
                if (this.controllo.nome == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.praticaid == "") {
                    result = 0;
                }
            }




            return result;

        },

        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },


        setPraticheOld: function (id_azienda, id_tipo_progetto) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("TIPO PROJ 2: ", id_tipo_progetto);

            console.log("PRAT TMP: ", this.praticheTemp);

            var Pratiche = [];

            if ((id_azienda != "") && (id_tipo_progetto != "")) {

                for (var i = 0; i < this.praticheTemp.length; i++) {

                    if ((parseInt(this.praticheTemp[i].companyid) == parseInt(id_azienda)) && (parseInt(this.praticheTemp[i].tipo_progetto_id) == parseInt(id_tipo_progetto))) {

                        Pratiche.push({
                            id: this.praticheTemp[i].id,
                            nome: this.praticheTemp[i].nome
                        });

                    }

                }

            }

            console.log("PRAT SEL: ", Pratiche);

            this.pratiche = Pratiche;

        },


        setPratiche: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);


            console.log("PRAT TMP: ", this.praticheTemp);

            var Pratiche = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.praticheTemp.length; i++) {

                    if (parseInt(this.praticheTemp[i].companyid) == parseInt(id_azienda)) {

                        Pratiche.push({
                            id: this.praticheTemp[i].id,
                            nome: this.praticheTemp[i].nome
                        });

                    }

                }

            }

            console.log("PRAT SEL: ", Pratiche);

            this.pratiche = Pratiche;

        },



        setScopes: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("SCOPES TMP: ", this.scopesTemp);

            var scopesAzienda = [];

            for (var i = 0; i < this.scopesTemp.length; i++) {

                if (parseInt(this.scopesTemp[i].company_id) == parseInt(id_azienda)) {

                    scopesAzienda.push({
                        id: this.scopesTemp[i].id,
                        name: this.scopesTemp[i].name
                    });

                }

            }

            console.log("SCOPES SEL: ", scopesAzienda);

            this.scopes = scopesAzienda;

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.controllo.companyid = azienda.companyid;

            //this.setPratiche(this.controllo.companyid, this.controllo.tipo_progetto_id);
            this.setPratiche(this.controllo.companyid);

            this.setupButtons();
        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                //var enableSave = this.enableSaveButton();
                var enableSave = 1;

                if (enableSave == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30
                    });



                }



                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });



                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);





        },


        validate() {

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {

                this.$swal({
                    icon: "error",
                    text: "Verifica i dati",
                    showConfirmButton: false,
                    timer: 2000
                });

            } else {

                var errore = 0;

                $("#itemCompany").removeClass("clDDLError");
                $("#itemCompany > ion-label").removeClass("clDDLLabelError");
                $("#itemCompany").addClass("clDDL");
                $("#itemCompany > ion-label").addClass("clDDLLabel");
                $("#msgErrCompany").html("&nbsp;");

                if (this.controllo.companyid == "") {

                    $("#itemCompany").removeClass("clDDL");
                    $("#itemCompany > ion-label").removeClass("clDDLLabel");
                    $("#itemCompany").addClass("clDDLError");
                    $("#itemCompany > ion-label").addClass("clDDLLabelError");
                    $("#msgErrCompany").html("Campo richiesto");

                    errore = 1;

                }

                $("#itemPratica").removeClass("clDDLError");
                $("#itemPratica > ion-label").removeClass("clDDLLabelError");
                $("#itemPratica").addClass("clDDL");
                $("#itemPratica > ion-label").addClass("clDDLLabel");
                $("#msgErrPratica").html("&nbsp;");

                if (this.controllo.praticaid == "") {

                    $("#itemPratica").removeClass("clDDL");
                    $("#itemPratica > ion-label").removeClass("clDDLLabel");
                    $("#itemPratica").addClass("clDDLError");
                    $("#itemPratica > ion-label").addClass("clDDLLabelError");
                    $("#msgErrPratica").html("Campo richiesto");

                    errore = 1;

                }




                if (errore == 0) {

                    console.log("ok");

                    this.saveData();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Verifica i dati",
                        showConfirmButton: false,
                        timer: 2000
                    });

                }




            }
        },




        validateOld() {

            var that = this;

            var errore = "";

            if ($("#companyid").val() == "") {
                errore = "Specificare l'azienda";
            }

            if (errore == "") {

                if ($("#name").val() == "") {
                    errore = "Specificare il nome";
                }

            }

            if (errore == "") {

                if ($("#departmentid").val() == "") {
                    errore = "Specificare il dipartimento";
                }

            }


            if ($("#group_scope_id").val() != "") {

                that.gruppo.group_scope_id = $("#group_scope_id").val();

            }


            if (errore == "") {


                that.saveData();

            }

            if (errore != "") {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        initInsertGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                this.aziende = res.data.aziende;

                this.dipartimentiTemp = res.data.dipartimenti;

                this.scopesTemp = res.data.scope;


                // imposto di default l'azienda del filtro
                var v_company = window.$cookies.get("sel_filter_azienda_gruppi");

                that.gruppo.companyid = v_company;

                this.setDipartimenti(v_company);

                this.setScopes(v_company);


                $("#itemCompany").addClass("item-has-value");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        initEditGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiscadenze.editScadenza(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editScadenza", res);

                that.setPratiche(res.data.result.companyid);

                //      that.controllo = res.data.result;

                that.controllo.companyid = res.data.result.companyid;

                that.controllo.praticaid = res.data.result.praticaid;

                that.controllo.nome = res.data.result.nome;

                that.controllo.descrizione = res.data.result.descrizione;


                that.controllo.delivery_date_time = res.data.result.delivery_date_time;

                that.controllo.delivery_hour_time = res.data.result.delivery_hour_time;

                that.controllo.idresources_group_name = res.data.result.idresources_group_name;

                that.controllo.frequency = res.data.result.frequency;

                that.controllo.timebeforesend = res.data.result.timebeforesend;


                that.controllo.send_as_mail = res.data.result.send_as_mail;

                that.controllo.send_as_sms = res.data.result.send_as_sms;

                that.controllo.send_as_wapp = res.data.result.send_as_wapp;


                if (res.data.controllo.companyid != null) {

                    $("#itemAzienda").addClass("item-has-value");

                }



                if (res.data.result.praticaid != null) {

                    $("#itemPratica").addClass("item-has-value");

                }

                // if (res.data.controllo.idresources_group_name != null) {

                //     $("#itemGruppo").addClass("item-has-value");

                // }




                // that.setupButtons();



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.getPianoOrario(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianoOrario", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

                this.pianoorario = res.data.piano_orario;

                $("#itemCompany").addClass("item-has-value");

                if (this.pianoorario.lunedi != null) {

                    $("#itemLunedi").addClass("item-has-value");

                }

                if (this.pianoorario.martedi != null) {

                    $("#itemMartedi").addClass("item-has-value");

                }

                if (this.pianoorario.mercoledi != null) {

                    $("#itemMercoledi").addClass("item-has-value");

                }

                if (this.pianoorario.giovedi != null) {

                    $("#itemGiovedi").addClass("item-has-value");

                }

                if (this.pianoorario.venerdi != null) {

                    $("#itemVenerdi").addClass("item-has-value");

                }

                if (this.pianoorario.sabato != null) {

                    $("#itemSabato").addClass("item-has-value");

                }

                if (this.pianoorario.domenica != null) {

                    $("#itemDomenica").addClass("item-has-value");

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {


            var v_token = window.$cookies.get("token");
            var that = this;

            console.log("CONTROLLO: ", that.controllo);
            console.log("CUR ID: ", that.cur_id);
            console.log("TOKEN: ", v_token);


            if (that.cur_id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apiscadenze.insertScadenza(
                    that.controllo,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertScadenza", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        // router.push({
                        //     path: "/scadenze"
                        // });

                        var id_pratica = window.$cookies.get("id_pratica");


                        router.push({
                            path: "/scadenze/" + id_pratica
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );


            }


            if (that.cur_id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apiscadenze.updateScadenza(
                    that.controllo,
                    v_token,
                    that.$route.params.id
                ).then((res2) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateScadenza", res2);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        // router.push({
                        //     path: "/scadenze"
                        // });

                        var id_pratica = window.$cookies.get("id_pratica");


                        router.push({
                            path: "/scadenze/" + id_pratica
                        });

                    }, 200);

                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err2);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response2);

                }

                );


            }





            // this.cur_id 

            // var id_rec = that.$route.params.id;

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apisedioperative.saveSedeOperativa(
            //     that.sede_operativa,
            //     v_token,
            //     id_rec
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from saveSedeOperativa", res);

            //     that.$swal({
            //         icon: "success",
            //         text: "Dati salvati correttamente",
            //         showConfirmButton: false,
            //         timer: 2000
            //     });

            //     setTimeout(() => {

            //         that.dialogMsg = false;
            //         that.setupButtons();

            //         router.push({
            //             path: "/sediOperative"
            //         });

            //     }, 200);

            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("Errori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );

        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.clNumLabel {
    left: 0px !important;
    right: auto !important;
    position: absolute !important;
}

.clNumLabelError {
    left: 0px !important;
    right: auto !important;
    position: absolute !important;
    color: red !important;
}


.clMsgError {
    color: red;
    margin-top: -11px;
    margin-left: 9px;
    font-size: 12px;
    font-weight: 600;
}

.clNumMsgError {
    color: red;
    margin-top: -26px;
    margin-left: 9px;
    font-size: 12px;
    font-weight: 600;
}

.clDDL {
    min-width: 190px !important;
    transform: translateX(-6px) !important;
    margin-top: -24px !important;
    margin-bottom: 18px !important
}

.clDDLLabel {
    font-size: 11px !important;
    color: black !important;
    cursor: pointer !important;
    font-weight: bold !important;
    opacity: 1 !important;
}


.clDDLError {
    min-width: 190px !important;
    transform: translateX(-6px) !important;
    margin-top: -24px !important;
    margin-bottom: 18px !important;
    border-bottom: 1px solid red !important;
}

.clDDLLabelError {
    font-size: 11px !important;
    color: red !important;
    cursor: pointer !important;
    font-weight: bold !important;
    opacity: 1 !important;
}




.w-container-edit-scadenza {
    width: 500px;
    padding: 0px;
    z-index: 3;
    overflow-x: hidden !important;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-edit-scadenza {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}

.edit_scadenza_agila .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_scadenza_agila .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.edit_scadenza_agila ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}



.edit_scadenza_agila .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.edit_scadenza_agila .v-input.cl-checkbox.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.primary--text {
    margin-top: 0px !important;
}

.edit_scadenza_agila .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox {
    margin-top: 0px !important;
}


.w-container-edit-scadenza .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}
</style>
